/* global Blockly */
import React from 'react'
import { timerIcon } from './index';


function defineBlock(context) {
    return {
        init: function() {
            let selectTimerDropdown = new Blockly.FieldWidgetsDropdown(context, 'timer', undefined, '- select -');

            this.appendDummyInput('WIDGET')
                .appendField("get value of timer ")
                .appendField(new Blockly.FieldImage(timerIcon, 30, 30, "timer icon", null), 'timerIcon')
                .appendField(selectTimerDropdown, "ID")
                .appendField(" as ")
                .appendField(new Blockly.FieldDropdown([['amount of seconds','seconds'], ['formatted string','string']]), "FORMAT")
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setOutput(true, ["String", "Number"]);
            this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
            this.setPreviousStatement(false);
            this.setNextStatement(false);
            this.setTooltip("Gets the value of the selected timer with the desired format.");
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            let id = block.getFieldValue('ID');
            let format = block.getFieldValue('FORMAT') === 'string';
            let code = `Snap.widgets.timer.getTimerValue("${id}", ${format})\n`;
            return [code, Blockly.JavaScript.ORDER_NONE];
        }
    }
}

const GetTimerValue = function (props) {
    let name = "snap_get_timer";
    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators(props.context);

    if (props.widgets.length < 1) {
        return null;
    }

    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }
    return <block type={name}></block>;
}

export default GetTimerValue;