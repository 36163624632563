import React from 'react'
import _ from "underscore";
import util from "../../../../utils/es5Utils";

export default function Charts  (props) {
    let widgets = util.widgetsOfType(props.context, "chart");
    let newProps = Object.assign({},props,{widgets});

    let components = [
        newProps.widgets.length>0?<label text={"Chart"}/> : null,
        ..._.values(props.childComponents).map((Component, id) => <Component.component { ...newProps } key={id}/>)
    ];

    return components
}
