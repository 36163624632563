import DropdownOptions from "./DropdownOptions";
import Checkbox from "./Checkbox";
import RequestResponseParameter from "./RequestResponseParameter";
import TryCatchException from "./TryCatchException";
import RestParams from "./RestParams";
import NumberText from "./NumberText";
import BooleanInput from "./Boolean";
import Label from './Label';
import CustomJSBlock from './CustomJSBlock';
import BlankBlock from "./BlankBlock";
import RequestErrorResponse from "./RequestErrorResponse";
import ConfirmDialogue from "./ConfirmDialogue";
import TextResponse from "./TextResponse";
import BooleanResponse from "./BooleanResponse";
import CustomNumber from "./CustomNumber";

const defineFunctions = [
    Checkbox,
    DropdownOptions,
    RequestResponseParameter,
    RestParams,
    NumberText,
    BooleanInput,
    Label,
    CustomJSBlock,
    BlankBlock,
    RequestErrorResponse,
    TryCatchException,
    ConfirmDialogue,
    TextResponse,
    BooleanResponse,
    CustomNumber
];

const AssistingBlocks = function(props){
    defineFunctions.forEach(x=>{x(props)});
    return null;
};

export default AssistingBlocks
