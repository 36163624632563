/* global Blockly */
import React from 'react';
import { groupListIcon } from "./index";


function defineBlock(context) {
    return {
        init: function () {
            let selectGroupListDropdown = new Blockly.FieldWidgetsDropdown(context, 'grouplist', undefined, '- select -');

            this.appendDummyInput('WIDGET')
                .appendField('get')
                .appendField(new Blockly.FieldImage(groupListIcon, 30, 30, "groupList icon", null), 'groupListIcon')
                .appendField(selectGroupListDropdown, 'ID')
                .appendField('group list');

            this.setPreviousStatement(false);
            this.setNextStatement(false);
            this.setOutput(true, "Array");
            this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Return the array of selected groups from a Group List widget.');
            this.setHelpUrl(`${Blockly.BASE_HELP_URL}#widget-values`);
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            let groupListDropdownId = block.getFieldValue('ID');
            var code = `Snap.widgets.grouplist.getSelectedGroups('${groupListDropdownId}')`;
            return [code, Blockly.JavaScript.ORDER_NONE];;
        }
    }
}

const GetGroupList = function (props) {
    const name = 'snap_grouplist_get_group_list';

    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators();
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    return props.widgets.length ? <block type={name}></block> : null;
}

export default GetGroupList