/* global Blockly */
import React from 'react';
import {businessCardIcon} from "./index";


function defineBlock(context) {
    return {
        init: function () {
            this.appendValueInput('VALUE')
                .appendField("set")
                .appendField(new Blockly.FieldImage(businessCardIcon, 30, 30, "business card icon", null), 'businessCardIcon')
                .appendField(new Blockly.FieldWidgetsDropdown(context, "businesscard", undefined, '- select -'), "ID")
                .appendField("field")
                .appendField(new Blockly.FieldDropdown([['Name','Name'],['Title','Title'],['Email','Email'],['Phone','Phone'],['Company','Company']],undefined,'- select -'), "TARGET")
                .appendField("to")

            this.setPreviousStatement(true);
            this.setNextStatement(true);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Set the value of a property in a business card widget');
            this.setHelpUrl(Blockly.BASE_HELP_URL + '#widget-values');
        },

        onchange: function(){
            if (this.getField('businessCardIcon') && this.getField('businessCardIcon').imageElement_)
                this.getField('businessCardIcon').setTooltip('BusinessCard');
        }
    }
}

function defineGenerators(){
    return {
        'JavaScript': function(block){
            let id = block.getFieldValue('ID');
            let field = block.getFieldValue('TARGET');
            let value = Blockly.JavaScript.valueToCode(block, 'VALUE', Blockly.JavaScript.ORDER_NONE);
            let code = `Snap.widgets.businesscardreader.set${field}("${id}",${value});`;
            return code;
        }
    }
}

const SetBusinesCardValue = function(props){
    const name = 'snap_set_businesscard_value';
    Blockly.Blocks[name] = defineBlock(props.context, props.widgets);
    let generators = defineGenerators(props.context, props.widgets);
    for(let i in generators){
        Blockly[i][name] = generators[i]
    }

    if (props.widgets.length < 1) {
        return null;
    }

    return (
        <block type={name}>
            {props.widget && (props.widget.id !== undefined) ?
                <field name='ID'>{props.widget.id}</field>
                : null
            }
            <value name="VALUE">
                <shadow type="text"/>
            </value>
        </block>
    )
}

export default SetBusinesCardValue
