/* global Blockly */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Actions} from '../actions';
import {blocklyPrefix, blocklyPostfix} from "../utils";
import parser, {stringify} from 'comment-parser';
import postRobot from 'post-robot';
import _ from 'underscore';
import initBlocklyOverrides from '../blockly'
import Workspace from '../components/Workspace';
import queryString from "query-string";
import SaveModal from "../components/SaveModal";
import CreateJSBlock from "../components/SaveModal/CreateJSBlock";
import DeleteLibraryDialog from "../components/DeleteLibraryDialog";
import SaveBlockTypeErrorDialog from "../components/SaveBlockTypeErrorDialog";
import SaveRESTBlockErrorDialog from "../components/SaveRESTBlockErrorDialog";
import CustomJSModal from '../components/CustomJSModal/CustomJSModal';
import ResizableModal from '../components/PopupModal/Modal';

class WorkspaceContainer extends Component {

    constructor(props) {
        super(props);
        this.mountRef = React.createRef();
        this.toolboxRef = React.createRef();
        this.state = {
            err: {
                msg: '',
                show: false,
                dismissErr: undefined
            },
            currentLibrary: null,
            isInitialized: false,
            restBlockError: false,
            modalContent: '', // Saved content in the popup modal
            modalOpen: false, // Whether the modal is visible or not
            modalChecked: true, // Whether or not the user wants to see the modal on launch
            modalPosition: { x: (window.innerWidth - 500) / 2, y: (window.innerHeight - 200) / 10, width: 500, height: 350 },  // Position and size of the modal
            customJSBlockId: '',
            customJSBlockCode: '',
            customJSBlockCodeCallback: null
        }
    }

    componentDidMount() {
        this._attachListeners();
        this.props.history.push(`/modal-popup${this.props.location.search}`);
    }

    _isAuthorized() {
        const {debug} = queryString.parse(window.location.search);
        const {isMockData} = this.props.app;
        if (isMockData && debug !== 'true') {
            return false;
        }
        return true;
    }

    _attachIframeListeners () {
        postRobot.on('requestOutput', () => {
            // this._sendCodeToParent();
            let err = this._validateOutput();
            if(err){
                // if an error is encountered set the workspace listener to check each event
                // whether the workspace is valid.
                this._setWorkspaceValidateListener();
                return {err}
            } else {
                return {
                    code: this._getBlocklyCode(),
                    properties: this._getProperties()
                }
            }
        });
    }

    _attachWorkspaceListeners () {
        document.addEventListener("BlockSave", e => {
            this.saveMyBlock(e.detail);
        });
    }

    _attachListeners() {
        this._attachIframeListeners();
        this._attachWorkspaceListeners();
    }

    _setWorkspaceValidateListener(){
        var onChange = () => {
            let err = this._validateWidgetSelect();
            if(!err){
                this.props.doSendValidatedToParent(true);
                this.workspace.removeChangeListener(onChange);
            } else{
                this.props.doSendValidatedToParent(false);
                console.log('not valid');
            }
        }
        this.workspace.addChangeListener(onChange);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.app.context && !this.state.isInitialized) {
            setTimeout(() => {
                this._initializeBlockly()
            });
        }
    }

    _initializeBlockly() {
        if (this._isAuthorized()) {
            this._mountBlockly();
            this.setState({isInitialized: true});
            const { tenantId = "-1", userId = "-1", accessToken } = this.props.app.context;
            localStorage.setItem('auth_token', accessToken);

            this.props.getLibraries({
                tenantId: tenantId,
                userId: userId
            });
        }
    }

    _getBlocklyCode() {
        var js = Blockly.JavaScript.workspaceToCode(this.state.workspace);
        js = js.replace(/SnapReturns\./g, '');
        var xml = Blockly.Xml.workspaceToDom(this.workspace);
        xml = new XMLSerializer().serializeToString(xml);
        var comment = stringify([{
            tags: [{
                tag: "v1",
                name: "xml",
                description: xml.replace(/\/\*/g, "!!comment-start!!").replace(/\*\//g, "!!comment-end!!"),
                line: 2
            }],
            line: 0,
            description: "Blockly Block",
        }]);
        const code = `${comment}\n${blocklyPrefix}\n${js}\n${blocklyPostfix}`;
        return code;
    }

    // provide a JSON describing all additional properties for this blockly session
    _getProperties() {
        var properties = {};

        // add popup settings, which describe how the use is supposed to use this script
        properties.popup = {
            content: this.state.modalContent, 
            checked: this.state.modalChecked,
            position: this.state.modalPosition
        };
        
        return properties;
    }

    _stepExecution() {
        if (!window.BlocklyDebuggers) return;
        var debuggr = window.BlocklyDebuggers;
        var block = debuggr.next();
        Blockly.mainWorkspace.highlightBlock(block.value);
    }

    _continueExecution() {
        if (!window.BlocklyDebuggers) return;
        var debuggr = window.BlocklyDebuggers;
        var previousBlock = debuggr.next();
        var block = debuggr.next();
        Blockly.mainWorkspace.highlightBlock(block.value);

        while (!block.done) {
            Blockly.mainWorkspace.highlightBlock(block.value);
            previousBlock = block;
            block = debuggr.next();
        }

        Blockly.mainWorkspace.highlightBlock(previousBlock.value, false);
    }

    _setAllHighlighted(block, highlighted) {
        block.setHighlightBlock(highlighted);
        block.getChildren().forEach((x) => {
            this._setAllHighlighted(x, highlighted)
        })
    }

    _disableDownStreamBlocks(block) {
        if (block === null) {
            return
        }
        block.setEnabled(false);
        if ((block.nextConnection !== null) && block.nextConnection.targetBlock()) {
            this._disableDownStreamBlocks.bind(this)(block.nextConnection.targetBlock());
        }
    }

    _selectEnableBlock() {
        let f = (e) => {
            this.workspace.removeChangeListener(f);
            let blockId = e.blockId || e.newValue;
            let block = this.workspace.getBlockById(blockId);
            if (block === null) {
                this.workspace.getAllBlocks().forEach(x => {
                    x.setHighlightBlock(false)
                });
                return;
            }
            block = block.getRootBlock();
            this.workspace.removeChangeListener(f);
            this.workspace.getAllBlocks().forEach(x => {
                x.setHighlightBlock(false)
            });
            this.workspace.getTopBlocks().forEach(this._disableDownStreamBlocks.bind(this));
            block.getDescendants().forEach(x => {
                x.setEnabled(true);
                this.props.doSendValidatedToParent(true);
            });
        }
        Blockly.mainWorkspace.getAllBlocks().forEach(block => {
            if (!block.isShadow()) {
                block.setHighlightBlock(true);
            }
        })
        Blockly.mainWorkspace.addChangeListener(f);
    }

    _containsEnabled(block) {
        let next = false;
        if (block.nextConnection && block.nextConnection.targetBlock()) {
            next = this._containsEnabled.bind(this)(block.nextConnection.targetBlock())
        }
        return block.isEnabled() || next;
    }

    _validateOutput() {
        let oneBlockError = this._validateOneBlock();
        if(oneBlockError){
            return oneBlockError
        }

        let validWidgetsError = this._validateWidgetSelect();
        if(validWidgetsError){
            return validWidgetsError;
        }

        let validRestBlockError = this.state.restBlockError;
        if (validRestBlockError) {
            this.getRestBlockError(validRestBlockError);
            return validRestBlockError;
        }
    }

    _validateWidgetSelect() {
        let disabled = _.flatten(this.workspace.getAllBlocks().filter(x => !x.isEnabled()).map(x => {
            return x.getInternalDescendants().map(y => y.id)
        }));

        let blocks = this.workspace.getAllBlocks().filter(block => {
            return !disabled.includes(block.id)
        });

        let err;
        blocks.forEach(block => {
            block.inputList.forEach(input => {
                input.fieldRow.filter(x => {
                    return x instanceof Blockly.FieldDropdown
                }).forEach(ddField => {
                    // If dd hasn't been set yet, set a warning on that dd, and set the onchange handler
                    // to remove the warning (and reset the previous onchange handler)
                    if (!ddField.hasBeenSet) {

                        ddField.setWarning(true);
                        var closure = ddField.onChange;
                        ddField.setOnChange(function (value) {
                            this.setWarning(false);
                            if (closure) {
                                closure.call(this, value);
                            }
                            this.setOnChange(closure);
                        })
                        err = {type:'WidgetSelect',msg:'One or more widget dropdowns were not selected.'};
                    }
                })
            })
        });
        return err
    }

    _validateOneBlock() {
        const topBlocks = this.workspace.getTopBlocks().filter(this._containsEnabled.bind(this));
        if (topBlocks.length > 1) {
            this.workspace.getAllBlocks().filter(x => {
                return x.isEnabled() && (!x.isShadow())
            }).forEach(x => {
                x.setHighlightBlock(true)
            });
            this.workspace.cleanUp();
            this.workspace.zoomToFit();
            this.workspace.getTopBlocks().forEach(x => {
                x.moveBy(50, 50)
            });
            const errMsg = `Blockly scripts must contain only one block structure (the current script has ${topBlocks.length}). Please either select a block or continue editing and delete, connect, or disable (right-click) unconnected blocks.`;
            let dismissError = () => {
                this.setState({err: {msg: errMsg, show: false}});
            }
            this.setState({
                err: {
                    msg: errMsg,
                    show: true,
                    blocking: true,
                    buttons: [
                        {
                            text: 'continue editing',
                            callback: () => {
                                Blockly.mainWorkspace.getAllBlocks().forEach(x => this._setAllHighlighted(x, false));
                                dismissError();
                            }
                        },
                        {
                            text: 'select a block',
                            callback: () => {
                                this._selectEnableBlock();
                                dismissError();
                            }
                        }
                    ]
                }
            });

            return {type:'OneBlockError',msg:errMsg};
        }
        // return true;
    }

    getRestBlockError(errMessage) {

        this.workspace.zoomToFit();
        const errMsg = errMessage;
        let dismissError = () => {
            this.setState({err: {msg: errMsg, show: false}});
        }
        this.setState({
            err: {
                msg: errMsg,
                show: true,
                blocking: true,
                buttons: [
                    {
                        text: 'continue editing',
                        callback: () => {
                            Blockly.mainWorkspace.getAllBlocks().forEach(x => this._setAllHighlighted(x, false));
                            dismissError();
                        }
                    }
                ]
            }
        });

    }

    setRestBlockError(errMessage) {
        if (this.state.restBlockError != errMessage) {
            this.setState({restBlockError: errMessage});
        }
    }

    // Functions to set props sent to the popup modal
    setContentModal(newContent) {
        this.setState({ modalContent: newContent });
    }

    setOpenModal() {
        this.setState(prevState => ({
            modalOpen: !prevState.modalOpen
        }));
    }

    setCheckModal() {
        this.setState(prevState => ({
            modalChecked: !prevState.modalChecked
        }));
    }

    setPositionModal(newPos) {
        this.setState({ modalPosition: newPos });
    }
    
    
    // _validateOutput() {
    //     const topBlocks = Blockly.mainWorkspace.getTopBlocks().filter(x=>{return x.isEnabled()});
    //     if (topBlocks.length > 1) {
    //         const errMsg = `Blockly scripts must contain only one block structure. The current script has ${topBlocks.length} - please connect, discard, or disable (right-click) any unconnected blocks.`;
    //         topBlocks.forEach(x => {
    //             this._setAllHighlighted(x, true)
    //         });
    //         this.workspace.cleanUp();
    //         this.setState({
    //             err: {
    //                 msg: errMsg,
    //                 show: true,
    //                 dismissErr: () => {
    //                     Blockly.mainWorkspace.getAllBlocks().forEach(x => this._setAllHighlighted(x, false));
    //                     this.setState({err: {msg: errMsg, show: false}})
    //                 }
    //             }
    //         });
    //         return false;
    //     }
    //     return true;
    // }

    _sendCodeToParent() {
        let err = this._validateOutput();

        if(err){
            this.props.doSendCodeToParent({err});
        } else {
            this.props.doSendCodeToParent({
                code: this._getBlocklyCode(),
                properties: this._getProperties()
            });
        }
    }

    _evalCode() {
        Blockly.JavaScript.init(Blockly.mainWorkspace);
        eval(this._getBlocklyCode());
    }

    _logCode() {
        console.log(this._getBlocklyCode());
    }

    _getXmlTagContent(code) {
        let parsedXml = parser(code);
        parsedXml = parsedXml[0] || null;

        if (parsedXml && parsedXml && parsedXml.tags) {
            return parsedXml.tags[parsedXml.tags.length - 1].description;
        }

        return ""
    }

    _getCodeFromParent() {
        // var xml = Blockly.Xml.workspaceToDom(this.workspace);
        // xml = new XMLSerializer().serializeToString(xml);
        let {code} = this.props.app.context;
        let xml = this._getXmlTagContent(code);
        xml = xml.replace(/!!comment-start!!/g, "/*").replace(/!!comment-end!!/g, "*/");
        code = code.replace(/!!comment-start!!/g, "/*").replace(/!!comment-end!!/g, "*/");
        return {code, xml}
    }

    // Get the boolen variable regarding whether the modal is checked or not from the js file with the xml of all the blocks
    _getBooleanFromFile() {
        let {properties} = this.props.app.context;
        
        if (properties && properties.popup) {
            return properties.popup.checked
        } else {
            return true;
        }
      }

    // Get the content of the popup modal from the js file with the xml of all the blocks
    _getContentFromFile() {
        try {
            let {properties} = this.props.app.context;
            if (properties && properties.popup) {
                return properties.popup.content
            } else {
                return ''
            }
          } catch (error) {
            console.error('Error reading file:', error);
            return null;
          }
    }

    // Get the position and size of the popup modal from the js file with the xml of all the blocks
    _getPositionFromFile() {
        let {properties} = this.props.app.context;

        if (properties && properties.popup) {
            return properties.popup.position
        } else {
            return { x: (window.innerWidth - 500) / 2, y: (window.innerHeight - 200) / 10, width: 500, height: 350 };
        }
    }

    // Function to get the position and content of the modal when already within blockly
    _getContentfromParent(open, checked) {
        this.setState(
            {
                modalOpen: open,
                modalChecked: checked,
            },
            () => {
                this.props.history.push(`/modal-popup${this.props.location.search}`);
            }
        );
    }

    _mountBlockly() {
        initBlocklyOverrides();
        eval(blocklyPrefix);

        this.workspace = Blockly.inject(this.mountRef.current, {
            toolbox: this.toolboxRef.current,
            media: "/vendor/pxt-blockly/media/",
            grid: {
                spacing: 50,
                length: 51,
                colour: "#555559",
                snap: false
            },
            zoom: {
                controls: true,
                startScale: 0.7,
                maxScale: 1.5,
                minScale: 0.3,
                scaleSpeed: 1.2
            },
            move: {
                wheel: true
            },
            sounds: false,
            renderer: "thrasos"
        });

        this.workspace.configureContextMenu = (options) => {
            // set up global context menu opions (when right-clicked on workspace)
            options.splice(4,2);
            var undo = options.find(function(item) { return item.text === Blockly.Msg["UNDO"]; });
            if (!!undo) {
                undo.icon = "snap-icon-undo";
                undo.iconHover = "snap-icon-undo_dual";
            }
            var redo = options.find(function(item) { return item.text === Blockly.Msg["REDO"]; });
            if (!!redo) {
                redo.icon = "snap-icon-redo";
                redo.iconHover = "snap-icon-redo_dual";
            }
            var addComment = options.find(function(item) { return item.text === Blockly.Msg["ADD_COMMENT"]; });
            if (!!addComment) {
                addComment.icon = "snap-icon-SMS";
                addComment.iconHover = "snap-icon-SMS_dual";
            }
            var cleanup = options.find(function(item) { return item.text === Blockly.Msg["CLEAN_UP"]; });
            if (!!cleanup) {
                cleanup.icon = "snap-icon-clean";
                cleanup.iconHover = "snap-icon-clean_dual";
            }
            var deleteAll = options.find(function(item) { return item.text.split(' ')[0] === Blockly.Msg["DELETE_X_BLOCKS"].split(' ')[0]; });
            if (!!deleteAll) {
                deleteAll.icon = "snap-icon-delete";
                deleteAll.iconHover = "snap-icon-delete_dual";
            }
            return options.push({
                text: "Copy JavaScript",
                enabled: true,
                icon: "snap-icon-script",
                iconHover: "snap-icon-script_dual",
                callback: () => {
                    // var code = Blockly.JavaScript.workspaceToCode(this.state);
                    var code = this._getBlocklyCode();
                    var t = document.createElement('textarea');
                    t.style.position = 'absolute';
                    t.style.left = '100%';
                    document.body.appendChild(t);
                    t.value = code;
                    t.select();
                    document.execCommand('copy');
                    document.body.removeChild(t);
                }
            }, )
        };

        Blockly.setTheme(Blockly.Themes.SOTI);
        // need to differentiate from other flyouts for onboarding to find the right one
        Blockly.mainWorkspace.getFlyout().svgGroup_.setAttribute("id", "flyout");
        let xml = this._getCodeFromParent()['xml']
        if (xml !== '') {
            let startBlocks = Blockly.Xml.textToDom(xml);
            Blockly.Xml.domToWorkspace(startBlocks, this.workspace);
            setTimeout(() => {
                const allBlocks = this.workspace.getAllBlocks();
                allBlocks && allBlocks.map(block => {
                    const isDisabled = block.disabled;
                    block.setDisabled(false);
                    block.setDisabled(isDisabled);
                });
            }, 0)
        }
        
        
        this._validateBlocks(this.props.app.context);

        // scroll saved block to center when boot up editor
        this.workspace.markFocused();
        this.workspace.setScale(this.workspace.options.zoomOptions.startScale);
        this.workspace.scrollCenter();

        Blockly.mainWorkspace.registerButtonCallback("createCustomJSBlock", this._createCustomJSBlock.bind(this))
        Blockly.mainWorkspace.registerButtonCallback("showDeleteLibraryDialog", this._showDeleteLibraryDialog.bind(this))

        // modify blockly behaviour based on called action
        if (this.props.app.context && this.props.app.context.actionName) {
            switch (this.props.app.context.actionName) {

                case 'tableview_delete_record': {
                    // for tableview delete record action, we should create default REST/delete block
                    if (this.workspace.topBlocks_.length == 0) {
                        var defaultRestBlock = this.workspace.newBlock("rest_service");
                        defaultRestBlock.initTableViewAction("DELETE");
                    }
                }

                case 'tableview_edit_record': {
                    // for tableview edit record action, we should create default REST/post block
                    if (this.workspace.topBlocks_.length == 0) {
                        var defaultRestBlock = this.workspace.newBlock("rest_service");
                        defaultRestBlock.initTableViewAction("PUT");
                    }
                }
            }
        }

        // Only render the modal if checked is true, so the user wants to popup to be shown on launch
        let tempBool = this._getBooleanFromFile() ;
        let retrievedModalContent = this._getContentFromFile();
        let retrievedModalPosition = this._getPositionFromFile();
        if (!tempBool) {
            this.setState({
                modalOpen: false,
                modalChecked: false,
                modalContent: retrievedModalContent,
                modalPosition: retrievedModalPosition
            });
        } else {
            this.setState({
                modalOpen: true,
                modalChecked: true,
                modalContent: retrievedModalContent,
                modalPosition: retrievedModalPosition,
            });
        }
    }

    // Checks if xml contains blocks that references widgets that are not in
    // this Snap app
    _validateBlocks(context) {

        // [{id,name,type,restrictions}]
        let widgets = _.flatten(context.pages.map(x => {
            return x.widgets;
        }));
        let ids = widgets.map(x => {
            return x.id
        });

        Blockly.mainWorkspace.getAllBlocks().forEach(block => {
            block.onLoad && block.onLoad();

            block.inputList.forEach(input => {
                input.fieldRow.forEach(field => {
                    if (field instanceof Blockly.FieldWidgetsDropdown) {
                        if (!ids.includes(field.getValue())) {
                            // FieldWidgetDropdown references a non-exiting widget Warn on that field, and set change to
                            // remove the warning (b.c. a correct value will then have be chosen) and then set the
                            // onchange to what it was


                            field.setToSelectOption();
                            field.setWarning(true);
                            var closure = field.onChange;
                            field.setOnChange(function (v) {
                                field.setWarning(false);
                                field.setOnChange(closure);
                                if (closure) {
                                    closure.call(field, v);
                                }
                            });
                        }
                    }
                })
            })
        })


        // let problemBlocks = Blockly.mainWorkspace.getAllBlocks().filter(block => {
        //     let fields = _.flatten(block.inputList.map(input => {
        //         return input.fieldRow
        //     }));
        //     let widgetDdFields = fields.filter(field => {
        //         return field instanceof Blockly.FieldWidgetsDropdown
        //     });
        //
        //     let isProblem = false;
        //     widgetDdFields.forEach(x => {
        //         if (!ids.includes(x.getValue())) {
        //             isProblem = true;
        //         }
        //     });
        //     return isProblem;
        // });
        //
        // // TODO: need different languages at some point
        // let warningMessage = `Warning, one or more widgets that are referenced in this script have either been deleted or changed ID. Please ensure the highlighted blocks are correct.`;
        //
        // problemBlocks.forEach(block => {
        //     block.setHighlightBlock(true);
        // });
        // if (problemBlocks.length) {
        //     let err = {
        //         msg: warningMessage,
        //         show: true,
        //         buttons: [
        //             {
        //                 text: 'okay',
        //                 callback: () => {
        //                     this.setState({err: {msg: warningMessage, show: false}});
        //                     problemBlocks.forEach(x => x.setHighlightBlock(false));
        //                 }
        //             }
        //         ]
        //     };
        //     this.setState({err});
        // }
    }

    UNSAFE_componentWillMount() {
        this.props.initializeApp();
        this.props.getInitInfo();
        this._initializeBlocklyConfig();
    }

    saveMyBlock (block) {
        const containsRestBlock = this._checkForRestBlock(block);
        if (!block) { // block is null
            return;
        } else if (!(block.previousConnection && block.nextConnection)) { // not a statement block
            this.props.history.push(`/save-block-type-error${this.props.location.search}`);
        } else if (containsRestBlock) { // block contains a REST API block
            this.props.history.push(`/save-rest-block-error${this.props.location.search}`);
        } else { // block can be saved
            const blockDom = Blockly.Xml.blockToDom(block);
            const blockText = `<xml>${Blockly.Xml.domToText(blockDom)}</xml>`;
            this.setState({ blockToSave: blockText });
            this.props.history.push(`/save-block${this.props.location.search}`);
        }
    }

    _checkForRestBlock (block) {
        if (block.type === "rest_service" || block.type === "load_data_table_rest") {
            return true;
        } else if (block.childBlocks_.length > 0) {
            for (let i = 0; i < block.childBlocks_.length; i++) {
                if (this._checkForRestBlock(block.childBlocks_[i])) {
                    return true;
                }
            }
        }
        return false;
    }

    _createCustomJSBlock () {
        this.props.history.push(`/create-js-block${this.props.location.search}`);
    }

    _openJSEditor (blockId, blockCallback, code) {
        this.setState({
            customJSBlockId: blockId,
            customJSBlockCode: code,
            customJSBlockCodeCallback: blockCallback
        });
        this.props.history.push(`/custom-js${this.props.location.search}`);
    }

    updateBlockCode(code) {
        if (this.state.customJSBlockCodeCallback) {
            this.state.customJSBlockCodeCallback(code);
        }

        Blockly.mainWorkspace.fireChangeListener({
            type: "JsEditorClose",
            blockId: this.state.customJSBlockId
        });
        this.props.history.push(`/${window.location.search}`);
    }

    _showDeleteLibraryDialog (info) {
        let libraryName = info.svgGroup_.parentElement.getElementsByClassName("blocklyFlyoutLabel")[0].textContent;
        this.setState({currentLibrary: libraryName})
        this.props.history.push(`/delete-library${this.props.location.search}`);
    }

    _initializeBlocklyConfig() {
        /**
         * The richness of block colours, regardless of the hue.
         * Must be in the range of 0 (inclusive) to 1 (exclusive).
         */
        Blockly.HSV_SATURATION = 0.702;

        /**
         * The intensity of block colours, regardless of the hue.
         * Must be in the range of 0 (inclusive) to 1 (exclusive).
         */
        Blockly.HSV_VALUE = 1;


        Blockly.Msg.WIDGET_VALUES_HUE = "#7200e7";
        Blockly.Msg.APP_ACTIONS_HUE = "#32b6e9";
        Blockly.Msg.CONNECTIONS_HUE = "#c5428e";
        Blockly.Msg.ENV_VARIABLES_HUE = "#0fbd8c";
        Blockly.Msg.XSIGHT_TOPICS_HUE = "#41644a";

        Blockly.Msg.LOGIC_HUE = "#33cc33";
        Blockly.Msg.LOOPS_HUE = "#263deb";
        Blockly.Msg.MATH_HUE = "#ff6f00";
        Blockly.Msg.TEXTS_HUE = "#454c50";
        Blockly.Msg.LISTS_HUE = "#cc00ff";
        Blockly.Msg.VARIABLES_HUE = "#cccc00";
        Blockly.Msg.PROCEDURES_HUE = "#006666";
        Blockly.Msg.VARIABLES_DYNAMIC_HUE = "#cccc00";
        Blockly.Msg.COLOUR_HUE = "#666633";
        Blockly.Msg.DATES_HUE = "#006699"; // TODO get real value from designers;
        Blockly.Msg.MYBLOCKS_HUE = "#553982";
        Blockly.Msg.DEBUG_HUE = "#CC0033";
        Blockly.Msg.MOBICONTROL_HUE = "#0074AA";

        Blockly.Themes.SOTI = {};

        Blockly.Themes.SOTI.defaultBlockStyles = {
            "widget_values_blocks": {
                "colourPrimary": Blockly.Msg.WIDGET_VALUES_HUE
            },
            "app_action_blocks": {
                "colourPrimary": Blockly.Msg.APP_ACTIONS_HUE
            },
            "connections_blocks": {
                "colourPrimary": Blockly.Msg.CONNECTIONS_HUE
            },

            "logic_blocks": {
                "colourPrimary": Blockly.Msg.LOGIC_HUE
            },
            "loop_blocks": {
                "colourPrimary": Blockly.Msg.LOOPS_HUE
            },
            "math_blocks": {
                "colourPrimary": Blockly.Msg.MATH_HUE
            },
            "text_blocks": {
                "colourPrimary": Blockly.Msg.TEXTS_HUE
            },
            "list_blocks": {
                "colourPrimary": Blockly.Msg.LISTS_HUE
            },
            "variable_blocks": {
                "colourPrimary": Blockly.Msg.VARIABLES_HUE
            },
            "variable_dynamic_blocks": {
                "colourPrimary": Blockly.Msg.VARIABLES_DYNAMIC_HUE
            },
            "procedure_blocks": {
                "colourPrimary": Blockly.Msg.PROCEDURES_HUE
            },
            "colour_blocks": {
                "colourPrimary": Blockly.Msg.COLOUR_HUE
            },
            "field_blocks": {
                "colourPrimary": Blockly.Colours.textField,
                "colourSecondary": Blockly.Colours.textField,
                "colourTertiary": Blockly.Colours.textField,
            },
            "hat_blocks": {
                "colourPrimary": "330",
                "hat": "cap"
            }
        };

        Blockly.Themes.SOTI.categoryStyles = {
            "widget_values_category": {
                "colour": Blockly.Msg.WIDGET_VALUES_HUE
            },
            "app_action_category": {
                "colour": Blockly.Msg.APP_ACTIONS_HUE
            },
            "connections_category": {
                "colour": Blockly.Msg.CONNECTIONS_HUE
            },

            "logic_category": {
                "colour": Blockly.Msg.LOGIC_HUE
            },
            "loop_category": {
                "colour": Blockly.Msg.LOOPS_HUE
            },
            "math_category": {
                "colour": Blockly.Msg.MATH_HUE
            },
            "text_category": {
                "colour": Blockly.Msg.TEXTS_HUE
            },
            "list_category": {
                "colour": Blockly.Msg.LISTS_HUE
            },
            "variable_category": {
                "colour": Blockly.Msg.VARIABLES_HUE
            },
            "variable_dynamic_category": {
                "colour": Blockly.Msg.VARIABLES_DYNAMIC_HUE
            },
            "procedure_category": {
                "colour": Blockly.Msg.PROCEDURES_HUE
            },
            "colour_category": {
                "colour": Blockly.Msg.COLOUR_HUE
            },
            "field_category": {
                "colour": Blockly.Colours.textField,
                "colourSecondary": Blockly.Colours.textField,
                "colourTertiary": Blockly.Colours.textField,
            }
        };

        Blockly.Themes.SOTI =
            new Blockly.Theme(Blockly.Themes.SOTI.defaultBlockStyles,
                Blockly.Themes.SOTI.categoryStyles);
    }

    getDeepComponent (pathname, libraries) {
        const {isSavingJsBlock} = this.props.block;
        const {userId = "-1", tenantId = "-1" } = this.props.app.context

        switch(pathname) {
            case '/save-block':
                return <SaveModal toolbox={this.toolboxRef.current} block={this.state.blockToSave} saveLibrary={this.props.doSaveLibrary} getLibraries={this.props.getLibraries} saveMyBlock={this.props.doSaveMyBlock} mainWorkspace={this.workspace} libraries={libraries} userId={userId} tenantId={tenantId} sendValidatedToParent={this.props.doSendValidatedToParent}/>
            case '/create-js-block':
                return <CreateJSBlock toolbox={this.toolboxRef.current} block={"<xml></xml>"} isSaving={isSavingJsBlock} saveLibrary={this.props.doSaveLibrary} getLibraries={this.props.getLibraries} saveBlock={this.props.doSaveJsBlock} mainWorkspace={this.workspace} libraries={libraries} userId={userId} tenantId = {tenantId} sendValidatedToParent={this.props.doSendValidatedToParent}/>
            case '/delete-library':
                return <DeleteLibraryDialog toolbox={this.toolboxRef.current} currentLibrary={this.state.currentLibrary} libraries={libraries} userId = {userId} tenantId = {tenantId} deleteLibrary={this.props.doDeleteLibrary} history={this.props.history}/>
            case '/save-block-type-error':
                return <SaveBlockTypeErrorDialog history={this.props.history} />
            case '/save-rest-block-error':
                return <SaveRESTBlockErrorDialog history={this.props.history} />
            case '/custom-js':
                return <CustomJSModal customJSBlockCode={this.state.customJSBlockCode} customJSBlockCodeChange={this.updateBlockCode.bind(this)}/>
            case '/modal-popup':
                return <ResizableModal open={this.state.modalOpen} content={this.state.modalContent} setContent={this.setContentModal.bind(this)} setOpen={this.setOpenModal.bind(this)} modalChecked={this.state.modalChecked} setModalChecked={this.setCheckModal.bind(this)} modalPosition={this.state.modalPosition} setModalPosition={this.setPositionModal.bind(this)}/>
        }
    }

    render() {
        //TODO:: get myBlocks
        // from props when integrated with backend
        // const myBlocks = [];

        const {myBlocks, jsBlocks, blockLibrary} = this.props.block;

        if (!this.props.app.isInitialized) {
            return null;
        }

        if (!this._isAuthorized()) {
            return <div className="hv-center">
                <p>SOTI Blockly is meant to run on SOTI products only. Please contact SOTI administrator.</p>
            </div>
        }

        return (
            <div className={"workspace-container"}>
                { this.getDeepComponent(window.location.pathname, blockLibrary) }
                <Workspace {...this.props}
                           myBlocks={myBlocks}
                           jsBlocks={jsBlocks}
                           libraries={blockLibrary}
                           sendCodeToParent={this._sendCodeToParent.bind(this)}
                           evalCode={this._evalCode.bind(this)}
                           logCode={this._logCode.bind(this)}
                           stepExecution={this._stepExecution.bind(this)}
                           continueExecution={this._continueExecution.bind(this)}
                           workspace={this.workspace}
                           mountRef={this.mountRef}
                           modalContent={this.state.modalContent}
                           modalOpen={this.state.modalOpen}
                           modalChecked={this.state.modalChecked}
                           toolboxRef={this.toolboxRef}
                           err={this.state.err}
                           saveMyBlock={this.saveMyBlock.bind(this)}
                           ref={(childComponent) => {
                               window.childComponent = childComponent
                           }}
                           deleteBlock={this.props.doDeleteBlock}
                           setRestBlockError = {this.setRestBlockError.bind(this)}
                           openJSEditor={this._openJSEditor.bind(this)}
                           modalPopup={this._getContentfromParent.bind(this)}
                ></Workspace>

            </div>
        )
    }
}

export default connect(state => state, Actions)(WorkspaceContainer);