/* global Blockly */
import React from 'react';
import util from '../../../utils/es5Utils'

function defineBlock(context, listviews) {
    var ddOpts = {};
        listviews.forEach(function (lv) {
            if(lv != undefined){
                ddOpts[lv.id] = lv.columns
            }
        });


    return {
        init: function () {
            var widgetDD = new Blockly.FieldWidgetsDropdown(context, "tableview", undefined, "- select -");
            this.appendValueInput("VALUE")
                .appendField("in table")
                .appendField(widgetDD, "ID")
                .appendField("set name of column")
                .appendField(new Blockly.FieldDropdown([['','']], undefined, '- select -'), "COLUMN")
                .appendField("to")
                .setCheck("String");
            widgetDD.setOnChange(this.updateColumns.bind(this));
            this.setPreviousStatement(true);
            this.setNextStatement(true);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip("Set the name of the specified column in a Table widget.");
            this.setHelpUrl(Blockly.BASE_HELP_URL + '#widget-values');
            this.updateColumns(this.getFieldValue("ID"));
        },
        onLoad: function () {
            var id = this.getFieldValue('ID');
            var widget;
            if (listviews.length > 0 && listviews[0] !== undefined) {
                widget = listviews.find(function (x) {
                    return x.id === id
                });
            }
            var opts;
            if (!widget) {
                console.warn('widget not found: ' + id);
                opts = [['','']];
            } else {
                opts = ddOpts[id];
            }

            var colID = this.getFieldValue('COLUMN');

            if(!opts.filter(function(opt) { return opt.id === colID }).length) {
                console.warn('option not found: ' + colID);
                var dd = this.getField('COLUMN');
                dd.setToSelectOption();
                dd.setWarning(true);
                var closure = dd.onChange;

                dd.setOnChange(function () {
                    dd.setWarning(false);
                    if (closure) {
                        closure();
                        dd.setOnChange(closure);
                    }
                })
            }
        },
        updateColumns: function (widgetID) {
            let input = this.getInput("VALUE");
            let opts, newField;
            input.removeField("COLUMN");
            if (this.getField('ID').hasBeenSet && typeof ddOpts[widgetID] !== 'undefined') {
                opts = ddOpts[widgetID] || [['', '']];
                let cols = [];
                for(var i =0; i < opts.length; i++){
                    cols[i] = [opts[i].name, opts[i].id];
                }
                newField = new Blockly.FieldDropdown(cols, undefined,'- select -');
            } else {
                newField = new Blockly.FieldDropdown([['', '']], undefined, '- select -');
                newField.setEnabled(false);
            }
            input.insertFieldAt(3, newField, "COLUMN");
        }
    }
}

function defineGenerators(context, widgets) {
    return {
        /*
         * @return {string}
         */
        "JavaScript": function (block) {
            var id = block.getFieldValue("ID");
            var value = util.parseString(Blockly.JavaScript.valueToCode(block, "VALUE", Blockly.JavaScript.ORDER_NONE));
            var columnId = block.getFieldValue('COLUMN');
            var widget = widgets.find(function (x) {return x.id===id});
            var columnIndex = -1;
            if (!!widget) {
                for(var i = 0; i < widget.columns.length; i++){
                    if(columnId == widget.columns[i].id){
                        columnIndex = i;
                        break;
                    }
                }
            }
            if(columnIndex == -1){
                console.warn("column not found");
                return 'console.warn("column not found");\n'
            }
            return 'Snap.widgets.tableview.setColumn("' + id + '", ' + columnIndex + ', "' + value + '");\n';
        }
    }
}

const SetColumnName = function (props) {
    const name = "snap_set_column_name";

    let widgets = (props.widgets && props.widgets.length > 0) ? props.widgets : [props.defaultWidget];

    Blockly.Blocks[name] = defineBlock(props.context, widgets);
    let generators = defineGenerators(props.context, widgets);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    return (
        <block type={name}>
            {props.widget && (props.widget.id !== undefined)
                ? <field name='ID'>{props.widget.id}</field>
                : null
            }
            <value name="VALUE">
                <shadow type="text"></shadow>
            </value>
        </block>
    )
}

export default SetColumnName;
