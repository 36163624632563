import React from 'react'
import _ from "underscore";

const Variables = function (props) {
    if(!props.context || _.isEmpty(props.context.environmentVariables)){
        return null;
    }

    return (
        <category name='Global Variables' id='EnvVariables' iconclass='category with-icon-paths snap-icon-global_variable_dual'>
            <label text="Global Variables"/>
            { _.values(props.blocks).map((Block, id) => <Block.component context={props.context} key={id}/>) }
        </category>
    )
}

export default Variables;
