/* global Blockly */
import React from 'react';
import util from "../../../utils/es5Utils";

function defineBlock(context) {
    return {
        init: function () {
            let selectRepeaterDropdown = new Blockly.FieldWidgetsDropdown(context, 'repeater', undefined, '- select -');

            this.appendDummyInput('WIDGET')
                .appendField("navigate")
                .appendField(selectRepeaterDropdown, "ID")
                .appendField("to entry #");

            this.appendValueInput("VALUE")
                .setCheck(["String", "Number"]);

            // set number properties
            var entryId = this.workspace.newBlock("custom_number");
            entryId.setProperties({
                defaultValue: 1, 
                minValue: 1, 
                maxValue: 999,
                maxLength: 3,
                allowDecimals: false 
            });
            entryId.setShadow(true);
            this.getInput("VALUE").connection.connect(entryId.outputConnection);

            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Navigate to entry (specified by its index) of repeater widget.');
            this.setHelpUrl(`${Blockly.BASE_HELP_URL}#widget-values`);
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            let repeaterId = block.getFieldValue('ID');
            let value = 'toNumber(' + Blockly.JavaScript.valueToCode(block, 'VALUE', Blockly.JavaScript.ORDER_FUNCTION_CALL) + ', 0)';
            let code = `Snap.widgets.repeater.navigateTo("${repeaterId}", ${value});\n`;
            return code;
        }
    }
}

const RepeaterNavigateTo = function (props) {
    const name = 'snap_repeater_navigate_to';

    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators(props.context);
    for (let i in generators) {
        Blockly[i][name] = generators[i];
    }

    return <block type={name}>
            </block>;
}

export default RepeaterNavigateTo