import React from 'react';
import { Provider } from 'react-redux';
import routes from "./routes";
import {BrowserRouter} from "react-router-dom";
import './App.scss';
import './Blockly.scss';
import './dark-theme.scss';

export default function App ({ store }) {
    return (
        <Provider store = { store }>
            <BrowserRouter>
                <div>
                    { routes }
                </div>
            </BrowserRouter>
        </Provider>
    );
}