import React from 'react'
import "./index.css";
import _ from "underscore";
import GetDaysApart from "./GetDaysApart"

const DatesCategory = (props) => {
    return <category name='Dates' iconclass='category with-icon-paths snap-icon-date_picker_dual'>
        <label text="Dates"/>
        { _.values(props.blocks).map((Block, id) => <Block.component context={props.context} key={id}/>) }
        <GetDaysApart />
    </category>
};

export default DatesCategory;
