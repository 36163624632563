import React from 'react'
import _ from "underscore";

// Colour, Colour, colour, colour
const Colour = (props) => {
    if(!props.context) return null;

    return (
        <category id="colour" name="Color" colour="%{BKY_COLOUR_HUE}" iconclass="category with-icon-paths snap-icon-theme_dual">
            <label text="Color"></label>
            { _.values(props.blocks).map((Block, id) => <Block.component context={props.context} key={id}/>) }
        </category>
    )
};

export default Colour