import React, {Component} from 'react';
import "./index.css";

class SearchBar extends Component {
    constructor(props) {
        super(props);
    }

    componentDidUpdate() {
        let searchBar = document.querySelector(".search_bar-container");
        searchBar.style.width = this.props.collapsed ? "46px" : "200px";
    }

    render() {
        return (
            <div className={"search_bar-container"}>
                { this.props.collapsed ? null :
                    <><div className={"search-icon-container"}>
                        <span className={"snap-icon-search"} />
                    </div>
                    <input type="text" id={"search-bar"} placeholder={"Search..."} value={this.props.searchQuery}
                        onChange={e => this.props.onSearch(e.target.value)}
                        onClick={e => e.target.value}
                        onFocus={() => {
                            this.props.searchQuery && this.props.onSearch(this.props.searchQuery);
                            this.props.onSearchFocus();
                            }}
                        onBlur={() => this.props.onSearchBlur()}
                    /></>
                }
                <div className={"expand-collapse-container " + (this.props.collapsed ? "collapsed" : "")} 
                    onClick={ this.props.collapsed ? this.props.expandButtonHandler : this.props.collapseButtonHandler }>
                    <span className={"snap-icon-chevron_right"}></span>
                </div>
            </div>
        );
    }
}

export default SearchBar;
