/* global Blockly */
import React, {Component} from 'react';
import {Parser} from 'acorn';
import {simple} from 'acorn-walk';
import AppTour from '../AppTour';
import Toolbox from '../Toolbox/Toolbox';
import KeyBindings from './KeyBindings';
import ErrorMessage from '../ErrorMessage/ErrorMessage'
import queryString from 'query-string';
import classNames from 'classnames';
import DeleteBlockDialog from "../DeleteBlockDialog";
import "./index.css";
import ActionButtons from "../ActionButtons";

class Workspace extends Component {

    componentDidUpdate(prevProps) {
        if (prevProps.myBlocks !== this.props.myBlocks) {
            let selected = document.querySelector(".blocklySelected");
            if (selected) {
                selected.style.opacity = "1";
                this.props.workspace.scrollCenter();
            }
        }
    }

    componentDidMount(){
        this.setState({
            userId: -1,
            tenantId: -1,
            libraryId: -1,
            blockId: -1,
            libraryName: "",
            blockName: ""
        });

        // Workspaces injection
        // var workspace = Blockly.inject('blocklyDiv', {toolbox: document.getElementById('toolbox')});
        setTimeout( () => {
            // keep track of mouse position
            document.addEventListener('mousemove', logMousePosition);
            var mouseX, mouseY;
            function logMousePosition(e) {
                mouseX = e.clientX;
                mouseY = e.clientY;
                checkDeleteIcon(mouseX, mouseY);
            }

            // if the mouse has left the region spanning a block and its corresponding garbage can in the flyout,
            // remove the garbage can
            function checkDeleteIcon(mouseX, mouseY) {
                if (document.getElementsByClassName("delete-block-icon-container").length > 0) {
                    let rect = document.getElementsByClassName("delete-block-icon-container")[0]
                        .getBoundingClientRect();
                    if (mouseX < rect.left || mouseX > rect.right || mouseY < rect.top ||
                        mouseY > rect.bottom) {
                        removeDeleteIcon();
                    }
                }
            }

            // removes the garbage can in the flyout and its container, if they exist
            function removeDeleteIcon() {
                let container = document.getElementsByClassName("delete-block-icon-container");
                let icon = document.getElementsByClassName("delete-block-icon");
                for (let i = 0; i < container.length; i++) {
                    container[i].remove();
                }
                for (let i = 0; i < icon.length; i++) {
                    icon[i].remove();
                }
            }

            function generateSnapReturns(blockId, blockType) {
                // create variable blocks for any SnapReturns variables found in the JavaScript code
                let block = Blockly.mainWorkspace.getBlockById(blockId);
                if (block != null && block.type === blockType) {
                    let blockScript = block.code || block.getCode();

                    // generate AST for code
                    let ast = Parser.parse(blockScript);

                    // find the expressions in the AST containing SnapReturns and keep track of all the variable names used
                    let vars = [];
                    simple(ast, {
                        ExpressionStatement(node) {
                            if (node.expression.left && node.expression.left.object &&
                                node.expression.left.object.name && node.expression.left.object.name === "SnapReturns" &&
                                node.expression.left.property && node.expression.left.property.name) {
                                vars.push(node.expression.left.property.name);
                            }
                        }
                    });

                    // create variable blocks for each of the SnapReturns variables
                    for (let i = 0; i < vars.length; i++) {
                        Blockly.mainWorkspace.createVariable(vars[i]);
                    }
                }
            }

            Blockly.mainWorkspace.addChangeListener(blocklyEvent => {
                switch (blocklyEvent.type) {
                    case Blockly.Events.UI: {
                        if (document.querySelector('.blocklyToolboxDiv').width > 44) {
                            const toolboxRows = document.querySelectorAll('.blocklyTreeRow');
                            [...toolboxRows].map(row => {
                                row.classList.add("blocklyTreeRowExpanded");
                            });
                        }

                        if (blocklyEvent.element === "category") {
                            const toolboxDiv = document.querySelector('.blocklyToolboxDiv');
                            const flyout = document.getElementById("flyout");
                            const flyoutScrollbars = document.querySelectorAll('.blocklyFlyoutScrollbar');
                            let toolboxWidth = toolboxDiv.offsetWidth;
                            flyout.style.transform = "translate(" + toolboxWidth + "px, 0px)";
                            let flyoutBoundingRectRight = flyout.getBoundingClientRect().right;
                            [...flyoutScrollbars].map(scrollbar => {
                                scrollbar.style.transform = "translate(" + (flyoutBoundingRectRight - 15) + "px, 2.5px)";
                            })

                            if (toolboxWidth > 40) {
                                const toolboxRows = document.querySelectorAll('.blocklyTreeRow');
                                [...toolboxRows].map(row => {
                                    row.classList.add("blocklyTreeRowExpanded");
                                });
                            }
                        }

                        // get names of libraries
                        let libraries = [];
                        for (let i = 0; i < this.props.libraries.length; i++) {
                            libraries.push(this.props.libraries[i].name);
                        }

                        // add garbage can icon to blocks in custom libraries
                        if (blocklyEvent.element === "category" && libraries.includes(blocklyEvent.newValue)) {
                            let flyoutDOM = document.getElementsByClassName("blocklyFlyout");
                            if (flyoutDOM && flyoutDOM.length > 1) {
                                // get DOM element for toolbox flyout (flyoutDOM[0] represents the trash can flyout)
                                flyoutDOM = flyoutDOM[1];

                                // increase width of flyout to accommodate garbage can
                                // let flyout = document.getElementById("flyout");
                                let flyoutWidth = flyoutDOM.getAttribute("width");
                                flyoutWidth = Number(flyoutWidth.match(/\d+/)[0]);
                                flyoutWidth = (flyoutWidth + 35) + "px";
                                flyoutDOM.setAttribute("width", flyoutWidth);

                                // move scrollbar to the right edge of the flyout
                                let flyoutScrollbar = document.getElementsByClassName("blocklyFlyoutScrollbar");
                                flyoutScrollbar = [].slice.call(flyoutScrollbar);
                                if (flyoutScrollbar.length > 1) {
                                    flyoutScrollbar = flyoutScrollbar[1];
                                    let flyoutScrollbarTransform = flyoutScrollbar.style.transform;
                                    let regex = `^\\D*(\\d+(?:\\.\\d+)?)`
                                    if (flyoutScrollbarTransform !== "" && flyoutScrollbarTransform.match(regex).length > 1) {
                                        let flyoutScrollbarTransformX = flyoutScrollbarTransform.match(regex)[1];
                                        let newFlyoutScrollbarTransformX = (Number(flyoutScrollbarTransformX) + 35).toString();
                                        flyoutScrollbar.style.transform = flyoutScrollbarTransform.replace(
                                            flyoutScrollbarTransformX, newFlyoutScrollbarTransformX
                                        );
                                    }
                                }

                                // get top blocks inside flyout
                                let draggables = flyoutDOM.getElementsByClassName("blocklyDraggable");
                                let draggablesArray = [].slice.call(draggables);
                                let i = 0;
                                while (i < draggablesArray.length) {
                                    // blocks nested inside custom blocks also appear as draggables in the DOM
                                    // remove these from the list as we only want the top blocks
                                    if (draggablesArray[i].parentNode.classList.contains("blocklyDraggable")) {
                                        draggablesArray.splice(i, 1);
                                    } else {
                                        i++;
                                    }
                                }

                                // create garbage can icon for a block when the mouse hovers over that block
                                for (let i = 0; i < draggablesArray.length; i++) {
                                    draggablesArray[i].addEventListener("mouseover", e => {
                                        if (document.getElementsByClassName("delete-block-icon-container").length === 0 &&
                                            document.getElementsByClassName("delete-block-icon").length === 0 &&
                                            e.buttons === 0) {
                                            let rect = draggablesArray[i].getBoundingClientRect();

                                            // create container that spans the block and the garbage can
                                            // (if the mouse is over this container, the garbage can should be visible)
                                            let deleteIconContainer = document.createElement("div");
                                            deleteIconContainer.classList.add("delete-block-icon-container")
                                            deleteIconContainer.style.left = rect.left + "px";
                                            deleteIconContainer.style.paddingLeft = (rect.right - rect.left + 40) + "px";
                                            deleteIconContainer.style.top = rect.top + "px";
                                            deleteIconContainer.style.paddingTop = "4px";
                                            deleteIconContainer.style.height = (rect.bottom - rect.top) + "px"
                                            deleteIconContainer.style.zIndex = "-1";
                                            deleteIconContainer.style.position = "absolute";

                                            // create garbage can icon
                                            let deleteIcon = document.createElement("div");
                                            deleteIcon.classList.add("delete-block-icon");
                                            deleteIcon.style.left = (rect.right + 10) + "px";
                                            deleteIcon.style.top = (rect.top + 4) + "px";
                                            deleteIcon.style.zIndex = "99";

                                            // if the garbage can icon is clicked, show a dialog to confirm if the user wants
                                            // to delete the block
                                            deleteIcon.addEventListener("pointerup", () => {
                                                removeDeleteIcon();

                                                // get user ID, library ID, block ID, and block name from the database
                                                let savedLibraries = this.props.libraries;
                                                let userId = -1;
                                                let tenantId = -1;
                                                let libraryId = -1;
                                                let blockId = -1;
                                                let blockName = "";
                                                for (let j = 0; j < savedLibraries.length; j++) {
                                                    if (savedLibraries[j].name === blocklyEvent.newValue) {
                                                        userId = savedLibraries[j].userId;
                                                        tenantId = savedLibraries[j].tenantId;
                                                        libraryId = savedLibraries[j].id;
                                                        blockId = savedLibraries[j].blocks[i].id;
                                                        blockName = savedLibraries[j].blocks[i].name;
                                                    }
                                                }

                                                this.setState({
                                                    userId: userId,
                                                    tenantId: tenantId,
                                                    libraryId: libraryId,
                                                    blockId: blockId,
                                                    libraryName: blocklyEvent.newValue,
                                                    blockName: blockName
                                                });

                                                this.props.history.push(`/delete-block${this.props.location.search}`);
                                            })

                                            // insert container and icon into DOM
                                            document.getElementById("workspaceMountDiv").appendChild(deleteIconContainer);
                                            document.getElementById("workspaceMountDiv").appendChild(deleteIcon);
                                        }
                                    });

                                    // if the mouse is pressed down over a block in the flyout, remove the garbage can icon
                                    draggablesArray[i].addEventListener("pointerdown", removeDeleteIcon);
                                }
                            }
                        }
                        break;
                    }
                    case Blockly.Events.CREATE: {
                        generateSnapReturns(blocklyEvent.blockId, "custom_js_block");
                        generateSnapReturns(blocklyEvent.blockId, "blank_block");
                        break;
                    }
                    case "JsEditorClose": {
                        generateSnapReturns(blocklyEvent.blockId, "snap_jsblocks_custom_js");
                        break;
                    }
                }
            });
        }, 1000);
    }

    getDeepComponent (pathname, deleteBlock) {
        switch(pathname) {
            case '/delete-block':
                return <DeleteBlockDialog userId={this.state.userId}
                                          tenantId={this.state.tenantId}
                                          libraryId={this.state.libraryId}
                                          blockId={this.state.blockId}
                                          libraryName={this.state.libraryName}
                                          blockName={this.state.blockName}
                                          history={this.props.history}
                                          deleteBlock={deleteBlock} />
        }
    }

    render() {
        const {actions} = queryString.parse(window.location.search);
        const {toolboxRef, mountRef, myBlocks, jsBlocks, libraries, deleteBlock, workspace, sendCodeToParent, evalCode, logCode, debug, stepExecution, continueExecution, debugCode, onSearch, app: {searchQuery}, setRestBlockError, modalOpen, modalContent, modalChecked} = this.props;

        return (
            <div className={classNames("workspace", {actions: actions === "true"})}>
                { this.getDeepComponent(window.location.pathname, deleteBlock) }
                <KeyBindings/>
                <AppTour/>
                <ErrorMessage show={this.props.err.show} errMsg={this.props.err.msg} buttons={this.props.err.buttons}/>
                {debug ?
                    <svg style={{display: 'none'}}>
                        <xml id="toolbox" ref={this.props.toolboxRef}></xml>
                    </svg>
                    : <Toolbox toolboxRef={toolboxRef} myBlocks={myBlocks} jsBlocks={jsBlocks} libraries = {libraries} workspace={workspace}
                               context={this.props.app.context} onSearch={onSearch} searchQuery={searchQuery} setRestBlockError={setRestBlockError}
                               openJSEditor={this.props.openJSEditor}/>
                }
                <div id={"workspaceMountDiv"} ref={mountRef}/>
                <svg width="14" height="14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <pattern id="backgroundimagepattern" patternUnits="userSpaceOnUse" width="14" height="14">
                            <image href="/images/workspace/wf-bg.png" x="0" y="0" width="14" height="14" />
                        </pattern>
                    </defs>
                </svg>
                {
                    actions === "true"
                        ? <div className={"actions-container"}>
                            {/*<button className={"save-myblocks-button"} onClick={ saveMyBlock }>Save</button>*/}
                            <button className='btn' onClick={continueExecution}>Continue</button>
                            <button className='btn' onClick={stepExecution}>Step</button>
                            <i>|</i>
                            <button className={"send-code-button btn"} onClick={sendCodeToParent}>Send</button>
                            <button className='btn' onClick={evalCode}>Eval</button>
                            <button className='btn' onClick={debugCode}>Debug</button>
                            <button className='btn' onClick={logCode}>Log Code</button>

                        </div>
                        : null
                }
                <ActionButtons saveMyBlock={this.props.saveMyBlock} modalOpen={modalOpen} modalPopup={this.props.modalPopup} modalContent={modalContent} modalChecked={modalChecked}/>

                {/*<div className={"save-region"}>*/}
                {/*    <div className={"save-dialog"}>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        );
    }
}


export default Workspace;