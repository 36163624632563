import React from 'react'
import _ from "underscore";

const WidgetValues = function (props) {
    const WidgetBlocks = props.blocks;
    let blocks = [];
    let sorted = Object.keys(WidgetBlocks).sort();
    for(let i in sorted){
        let widgetType = sorted[i];
        let Component = WidgetBlocks[widgetType].component;
        let childComponents = WidgetBlocks[widgetType].childComponents;
        blocks.push(<Component key={i} {...props} childComponents={childComponents}/>);
    }

    let appHasWidgets = _.flatten(props.context.pages.map(x => {
        return x.widgets;
    })).filter(x => {
        return WidgetBlocks[x.type] && WidgetBlocks[x.type].component;
    }).length > 0;

    // if app has no widgets, just define the block definitions but don't render the toolbox xml
    if(!appHasWidgets){
        return null;
    }

    return (
        <category name='Widget Values' id='widgetValues' iconclass='category with-icon-paths snap-icon-widget_value_dual'>
            {blocks}
        </category>
    )
};

export default WidgetValues;
