/* global Blockly */
import React  from 'react';
import {checkBoxListIcon} from "./index";

function defineGenerators() {
    return {
        "JavaScript": function(block) {
            let id = block.getFieldValue("ID");
            let valueType = block.getFieldValue("VALUETYPE");
            let outputType = block.getFieldValue("AS");
            let code = "$('[id=\"" + id + "\"] input:checked').map(function(i,obj) {return {text: $(\"label[for='\"+$(obj).attr(\"id\")+\"']\").text().trim(),value: $(obj).val()}}).toArray()";

            if(valueType==="VALUE"){
                code = code+".map(function(x){return x.value})"
            } else if(valueType==="TEXT"){
                code = code+".map(function(x){return x.text})"
            }
            if(outputType === "CSV") {
                code = code+".join(',')"
            }
            return [code, Blockly.JavaScript.ORDER_NONE];
        }
    }
}

function defineBlock(context){
    return {
        init:function(){
            this.appendDummyInput()
                .appendField(new Blockly.FieldImage(checkBoxListIcon, 30, 30, "check box list icon", null), 'checkBoxListIcon')
            this.appendDummyInput('VALUE')
                .appendField("get list of")
                .appendField(new Blockly.FieldDropdown([["values","VALUE"],["text","TEXT"],["text and values","BOTH"]],this.changeValueType.bind(this)),"VALUETYPE")
                .appendField("of checked options in")
                .appendField(new Blockly.FieldWidgetsDropdown(context,"checkboxlist",undefined,'- select -'),"ID")
                .appendField("as")
                .appendField(new Blockly.FieldDropdown([['a list','LIST'],['CSV text','CSV']],this.changeOutputType.bind(this)),"AS");
            this.setPreviousStatement(false);
            this.setNextStatement(false);
            this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip(function(){
                let valtype = this.getFieldValue("VALUETYPE");
                switch (valtype){
                    case "VALUE":
                        return "returns a list of the values associated with checked checkboxes in the specified checkboxlist";
                    case "TEXT":
                        return "returns a list of the text associated with checked checkboxes in the specified checkboxlist";
                    case "BOTH":
                        return "returns a list of the text and values associated with checked checkboxes in the specified checkboxlist in the format: [ {\"text\":< checkbox text >, \"value\":< checkbox value>}... ]";
                    default:
                        break;
                }
                return 'returns a list of details of the checked checkboxes in the specified checkboxlist'
            }.bind(this));
            this.setHelpUrl(Blockly.BASE_HELP_URL+'#widget-values');
            this.changeOutputType(this.getFieldValue('AS'));
        },
        onchange: function(){
            if (this.getField('checkBoxListIcon') && this.getField('checkBoxListIcon').imageElement_)
                this.getField('checkBoxListIcon').setTooltip('Checkbox List');
        },
        changeValueType: function(val){
            let asValue = this.getFieldValue("AS");
            let input = this.getInput("VALUE");
            input.removeField("AS");
            let newField;
            if(val==="BOTH"){
                newField = new Blockly.FieldDropdown([['a list','LIST']]);
                if(asValue !=='LIST'){
                    this.setWarningText("text and values cannot be output as CSV text, only as a list");
                }
            } else {
                newField = new Blockly.FieldDropdown([['a list','LIST'],['CSV text','CSV']]);
                newField.setValue(asValue);
                this.setWarningText(false);
            }
            input.insertFieldAt(5,newField,"AS");
            newField.setValidator(this.changeAs.bind(this));
            this.changeOutputType(this.getFieldValue("AS"));
        },
        changeAs: function(val){
            let valueType = this.getFieldValue('VALUETYPE');
            let input = this.getInput('VALUE');
            let newField;
            input.removeField('VALUETYPE');
            if(val==='CSV'){
                newField = new Blockly.FieldDropdown([["values","VALUE"],["text","TEXT"]]);
                if(valueType === "BOTH"){
                    this.setWarningText("text and values cannot be output as CSV text, only as a list");
                }
            } else{
                newField = new Blockly.FieldDropdown([["values","VALUE"],["text","TEXT"],["text and values","BOTH"]]);
                this.setWarningText(false);
            }
            newField.setValue(valueType);

            input.insertFieldAt(1,newField,"VALUETYPE");
            newField.setValidator(this.changeValueType.bind(this));
            this.changeOutputType(val);
        },
        changeOutputType: function(val){
            this.setOutput(true,val==='LIST'?'Array':'String')
        }
    }
}


const GetCheckboxlistSelectedCheckboxes = function (props){
    const name = "snap_get_checkboxlist_selected_checkboxes";

    let widgets = props.widgets.length>0?props.widgets:[props.defaultWidget];

    Blockly.Blocks[name] = defineBlock(props.context, widgets);
    let generators = defineGenerators(props.context, widgets);
    for(let i in generators){
        Blockly[i][name] = generators[i]
    }

    if(props.widgets.length<1){
        return null;
    }

    return (
        <block type={name}>
            {props.widget && (props.widget.id !== undefined)
                ? <field name='ID'>{props.widget.id}</field>
                : null
            }
        </block>
    )
}

export default GetCheckboxlistSelectedCheckboxes