/* global Blockly */
import React from 'react';

const SnapWait = function () {
    const name = 'snap_wait';
    const promisesExecutionString = "BlocklyPromises.length && ExecutionRuntimeModule.Execute(BlocklyPromises);"

    Blockly.Blocks[name] = {
        init: function() {
            this.appendDummyInput()
                .appendField("wait for ")
                .appendField(new Blockly.FieldNumber(0, 0), "DURATION")
                .appendField("(s) then do");
            this.appendStatementInput("CALLBACK")
                .setCheck(null);
            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setColour(Blockly.Msg.LOGIC_HUE);
            this.setTooltip('Wait for some seconds then execute a statement.');
            this.setHelpUrl(Blockly.BASE_HELP_URL+"#logic");
        }
    };

    Blockly.JavaScript[name] = function(block) {
        let duration = block.getFieldValue('DURATION');
        let statements_callback = Blockly.JavaScript.statementToCode(block, 'CALLBACK');
        let code = '' +
            'setTimeout(function(){' +
            statements_callback +
            promisesExecutionString +
            '},' + duration*1000 + ');\n';
        return code;
    };
    return (
        <block type={name}></block>
    )
};

export default SnapWait;