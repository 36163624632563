/* global Blockly */
import React from 'react';
/*
import util from "../../../../utils/es5Utils";
import {textBoxIcon} from "./index";
*/


function defineBlock() {

    return {
        init: function () {
            let testButton = {primary:'#ffffff', secondary:'#000000', tertiary:'#C1C1C1', text:'#7200e7'};

            this.appendValueInput("VALUE")
                .appendField("play ")
                .appendField(new Blockly.FieldDropdownSearch([["C", "261.63"], ["C#", "277.18"], ["D", "293.66"], ["D#", "311.13"], ["E", "329.63"], ["F", "349.23"], ["F#", "369.99"], ["G", "392.00"], ["G#", "415.30"], ["A", "440.00"], ["A#", "466.16"], ["B", "493.88"]], undefined, undefined), "NOTE")
                .appendField(" for ")
                .appendField(new Blockly.FieldDropdown([["1/16", "0.0625"], ["1/4", "0.25"], ["1/2", "0.5"],  ["1", "1"], ["2", "2"]], undefined, undefined), "BEATS")
                .appendField(" second(s) at volume ")
                .setCheck(["Number"])
            
            var volumeId = this.workspace.newBlock("custom_number");
            volumeId.setProperties({
                    defaultValue: 100, 
                    minValue: 0, 
                    maxValue: 100,
                    maxLength: 3,
                    allowDecimals: false 
                });
            volumeId.setShadow(true);
            this.getInput("VALUE").connection.connect(volumeId.outputConnection);

            
            this.appendDummyInput("TEXT")
                .appendField("%  ")         
            this.appendDummyInput('TEST')
                .appendField(new Blockly.FieldButton( ' Test ', testButton, this._testSound.bind(this)),'TEST')
                .setOnNewRow(false)
                

            this.appendDummyInput("FINISHED")
                .appendField("on sound finished")
                .setOnNewRow(true);

            this.appendStatementInput('CALLBACK');

            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Play a note for a specified length.');
            this.setHelpUrl(Blockly.BASE_HELP_URL + '#widget-values');
        },
        _testSound: function (){
            let note = this.getFieldValue('NOTE');
            let beats = this.getFieldValue('BEATS');
            let value = Blockly.JavaScript.valueToCode(this, 'VALUE', Blockly.JavaScript.ORDER_NONE);
            let volume = isNaN(value) ? 100 : value > 100 ? 100 : value < 0 ? 0 : value;
            let timing =  1000;
            timing = timing * beats;
            const context = new AudioContext();
            const gainNode = context.createGain();
            const oscillator = context.createOscillator();
            gainNode.gain.value = volume;                
            oscillator.frequency.value = note;
            oscillator.connect(gainNode);
            gainNode.connect(context.destination);
            oscillator.start(0);
            setTimeout(() => oscillator.stop(), timing);
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            let note = this.getFieldValue('NOTE');
            let beats = this.getFieldValue('BEATS');
            let timing =  1000;
            let value = Blockly.JavaScript.valueToCode(block, 'VALUE', Blockly.JavaScript.ORDER_NONE);
            timing = timing * beats;
            let callback = Blockly.JavaScript.statementToCode(block, 'CALLBACK');
            let code = `
                (function (){
                const context = new AudioContext();
                const gainNode = context.createGain();
                const oscillator = context.createOscillator();                
                oscillator.frequency.value = ${note};
                let volume = isNaN(${value}) ? 100 : ${value} > 100 ? 100 : ${value} < 0 ? 0 : ${value};
                gainNode.gain.value = volume;
                oscillator.connect(gainNode);
                gainNode.connect(context.destination);
                oscillator.start(0);
                setTimeout(() => {
                    oscillator.stop(); 
                    continueExecution();
                    function continueExecution() {
                    ${callback}
                    };
                }, 
                ${timing});
                })();\n`;
            return code;
        }
    }
}


const PlayNote = function () {
    let name = 'snap_sound_play_standard_sound';

    Blockly.Blocks[name] = defineBlock();
    let generators = defineGenerators();
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }
  
    return <block type={name}></block>;
}

export default PlayNote
