/* global Blockly */
import React from 'react';

function defineBlock() {
    return {
        init: function () {
            this.appendValueInput('HEADER')
                .setCheck("String")
                .appendField('confirm');
            this.appendValueInput('MESSAGE')
                .setCheck("String")
                .appendField('with message');

            this.addInput();
            this.setPreviousStatement(false);
            this.setNextStatement(false);
            this.setOutput(true, "Boolean");
            this.setOutputShape(Blockly.OUTPUT_SHAPE_HEXAGONAL);
            this.setShadow(true);
            this.setHelpUrl(Blockly.BASE_HELP_URL + "#text");
            this.setColour(Blockly.Msg.TEXTS_HUE);
        },

        addInput() {
          let headerInput = this.inputList[0];
          let messageInput = this.inputList[1];

          let headerBlock = this.workspace.newBlock('fixed_width_text');
          headerBlock.setShadow(true);
          let messageBlock = this.workspace.newBlock('fixed_width_text');
          messageBlock.setShadow(true);

          headerInput.connection.connect(headerBlock.outputConnection);
          messageInput.connection.connect(messageBlock.outputConnection);
          this.length++;
          this.initSvg();
          headerBlock.initSvg();
          messageBlock.initSvg();
          headerBlock.render();
          messageBlock.render();
          this.render();

        }


    }
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            let header = Blockly.JavaScript.valueToCode(block, 'HEADER', Blockly.JavaScript.ORDER_NONE);
            let message = Blockly.JavaScript.valueToCode(block, 'MESSAGE', Blockly.JavaScript.ORDER_NONE);
            let code = "{header: " + header + ", message: " + message + "}";
            return [code, Blockly.JavaScript.ORDER_NONE];
        }
    }
}

const ConfirmDialogue = () => {
    const name = 'confirm_dialogue';
    Blockly.Blocks[name] = defineBlock();
    let generators = defineGenerators();
    for (let i in generators) {
        Blockly[i][name] = generators[i];
    }

    return (
        <block type={name}>
              <value name="HEADER">
                <shadow type="text"></shadow>
            </value>
            <value name="MESSAGE">
                <shadow type="text"></shadow>
            </value>
        </block>
    )
};

export default ConfirmDialogue;