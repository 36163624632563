import React from 'react'
import _ from "underscore";

// Logic, logic, logic

const Logic  = function(props){
        return (
            <category name='Logic' id='logic' iconclass='category with-icon-paths snap-icon-logic_dual'>
                <label text="Logic"/>
                { _.values(props.blocks).map((Block, id) => <Block.component context={props.context} key={id}/>) }
            </category>
        )
}

export default Logic;