/* global Blockly */
import React from 'react'
import { timerIcon } from './index';


function defineBlock(context) {
    return {
        init: function() {
            let selectTimerDropdown = new Blockly.FieldWidgetsDropdown(context, 'timer', undefined, '- select -');

            this.appendDummyInput('WIDGET')
                .appendField("start timer")
                .appendField(new Blockly.FieldImage(timerIcon, 30, 30, "timer icon", null), 'timerIcon')
                .appendField(selectTimerDropdown, "ID");
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setOutput(false);
            this.setPreviousStatement(true);
            this.setNextStatement(true);
            this.setTooltip("Starts the selected timer.");
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            let id = block.getFieldValue('ID');
            let code = `Snap.widgets.timer.startTimer("${id}");\n`;
            return code;
        }
    }
}

const StartTimer = function (props) {
    let name = "snap_start_timer";
    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators(props.context);

    if (props.widgets.length < 1) {
        return null;
    }

    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }
    return <block type={name}></block>;
}

export default StartTimer;