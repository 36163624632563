/* global Blockly */

/*
This block is used for rendering custom number as a block (i.e. it can use connections and can be shadowed).
The implementation is based on the customized version of FieldNumber field.
*/
const CustomNumber = function () {
    var name = 'custom_number';

    Blockly.Blocks[name] = {
        properties: null,

        init: function() {
            this.appendDummyInput('NUM')
                .appendField('', "VALUE");
            this.setProperties(this.properties);

            this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
            this.setColour('#FFFFFF');
            this.setShadowColour('#FFFFFF');
            this.setOutput(true, "Number");
        },

        setProperties: function(properties) {
            if (!properties) {
                return;
            }
            let input = this.getInput('NUM');
            if (!!input) {
                input.removeField('VALUE');
            }
            let number = new Blockly.FieldNumber(
                properties.defaultValue, 
                properties.minValue /* min value also affects if negatives are allowed */, 
                properties.maxValue, 
                properties.allowDecimals ? 0 /* if decimal precision is needed, we should update this */ : 1 /* no decimals allowed */);
            if (!!properties.maxLength) {
                number.maxDisplayLength = properties.maxLength;
            }
            input.appendField(number, 'VALUE');
            if (this.rendered) {
                this.initSvg();
                this.render();
            }
            this.properties = properties;
        },

        mutationToDom: function() {
            let mutation = Blockly.utils.xml.createElement('mutation');
            mutation.setAttribute('properties', !!this.properties ? JSON.stringify(this.properties) : '');
            return mutation;
        },

        domToMutation: function(xml) {
            let code = xml.getAttribute("properties");
            if (!!code) {
                this.setProperties(JSON.parse(code));
            }
        },

    };

    Blockly.JavaScript[name] = function() {
        var code = parseInt(this.getFieldValue('VALUE'));
        var order = code >= 0 ? Blockly.JavaScript.ORDER_ATOMIC : Blockly.JavaScript.ORDER_UNARY_NEGATION;
        return [code, order];
    };
};

export default CustomNumber;
