import React from 'react';
import util from "../../../../utils/es5Utils";
import _ from "underscore";

export const groupListIcon = "data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.75084 16.3235C8.93447 16.3235 9.89399 15.3639 9.89399 14.1803C9.89399 12.9967 8.93447 12.0371 7.75084 12.0371C6.5672 12.0371 5.60768 12.9967 5.60768 14.1803C5.60768 15.3639 6.5672 16.3235 7.75084 16.3235ZM7.75084 18.038C9.88138 18.038 11.6085 16.3108 11.6085 14.1803C11.6085 12.0498 9.88138 10.3226 7.75084 10.3226C5.6203 10.3226 3.89316 12.0498 3.89316 14.1803C3.89316 16.3108 5.6203 18.038 7.75084 18.038ZM6.03631 20.6098C4.61595 20.6098 3.46452 21.7612 3.46452 23.1816V25.75H1.75V23.1816C1.75 20.8143 3.66905 18.8952 6.03631 18.8952H9.46536C11.8326 18.8952 13.7517 20.8143 13.7517 23.1816V25.75H12.0371V23.1816C12.0371 21.7612 10.8857 20.6098 9.46536 20.6098H6.03631ZM13.5612 10.7246C13.0919 10.4626 12.5709 10.3226 12.0372 10.3226L12.0371 11.9904C12.5534 11.9904 13.0486 12.2211 13.4136 12.6318C13.7787 13.0425 13.9838 13.5995 13.9838 14.1803C13.9838 14.7611 13.7787 15.3181 13.4136 15.7288C13.0486 16.1395 12.5534 16.3702 12.0371 16.3702V18.038C12.5709 18.038 13.0919 17.898 13.5612 17.636C13.8915 17.4517 14.1962 17.2069 14.4619 16.9081C15.1049 16.1846 15.4662 15.2034 15.4662 14.1803C15.4662 13.1572 15.1049 12.176 14.4619 11.4525C14.1962 11.1537 13.8915 10.9089 13.5612 10.7246ZM13.7906 20.6098H13.323V18.8952H13.7906C16.3731 18.8952 18.4666 20.8143 18.4666 23.1816V25.75H16.5962V23.1816C16.5962 21.7612 15.3401 20.6098 13.7906 20.6098Z' fill='%23EEEEEE'/%3E%3Cpath d='M22.3209 10.3226H16.3201V12.0371H22.3209V10.3226Z' fill='%23EEEEEE'/%3E%3Cpath d='M25.75 6.03631H16.3201V7.75084H25.75V6.03631Z' fill='%23EEEEEE'/%3E%3Cpath d='M25.75 1.75H16.3201V3.46452H25.75V1.75Z' fill='%23EEEEEE'/%3E%3C/svg%3E";

export default function GroupList(props) {

    let widgets = util.widgetsOfType(props.context, 'grouplist');
    let newProps = Object.assign({}, props, { widgets });

    let components = [
        newProps.widgets.length ? <label text={"Group Lists"} /> : null,
        ..._.values(props.childComponents).map((Component, id) => <Component.component {...newProps} key={id} />)
    ];

    return components;
}

