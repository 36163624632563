import React from 'react';
import util from "../../../utils/es5Utils";
import _ from "underscore";

const Repeater  = function(props){
    let widgets = util.widgetsOfType(props.context, "repeater");
    let newProps = Object.assign({},props,{widgets});

    return (
        <category name='Repeater' id='repeater' iconclass='category with-icon-paths snap-icon-repeater_dual' hidden={widgets.length === 0}>
            <label text={"Repeater"}/>
            {_.values(props.blocks).map((Component, id) => <Component.component { ...newProps } key={id}/>)}
        </category>
    )
}

export default Repeater;
