/* global Blockly */
import React from 'react'
import { xSightTopicIcon } from './index';

function defineBlock(context) {
    let xSightTopicNames = (context.xSightTopics && context.xSightTopics.length) ? context.xSightTopics.map(x => [x.name, x.name]) : [['- select -', 'TopicName']];

    return {
        init: function () {
            this.appendDummyInput('WIDGET')
                .appendField('get')
                .appendField(new Blockly.FieldImage(xSightTopicIcon, 30, 30, "xSight icon", null), 'xSightIcon')
                .appendField(new Blockly.FieldVariable(),'VARIABLE')
                .appendField('from')
                .appendField(new Blockly.FieldDropdownSearch(xSightTopicNames, undefined, '- select -'), 'TOPIC');

            this.appendStatementInput('CALLBACK');

            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setColour(Blockly.Msg.XSIGHT_TOPICS_HUE);
            this.setTooltip('Get the value of a Snap xSight topic.');
            this.setHelpUrl(Blockly.BASE_HELP_URL + "#variables");
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            let xSightTopicName = block.getFieldValue('TOPIC');
            let variableName = Blockly.JavaScript.variableDB_.getName(block.getField('VARIABLE').getText(), Blockly.Variables.NAME_TYPE);
            let callback = Blockly.JavaScript.statementToCode(block, 'CALLBACK');
            return 'Snap.xSightTopic.getValue("' + xSightTopicName + '", function(' + variableName + ') {' + callback + '});\n';
        }
    }
}

const GetXSightTopic = function (props) {
    const name = 'snap_xsight_topics_get';

    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators();
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    return <block type={name}></block>;
}

export default GetXSightTopic
