import React from 'react';
import _ from "underscore";

const Debug = (props) => {
    // [BreakPoint, StepThrough, Log].forEach(block => block({ context: props.context }));

    return (
        <category name='Debug Tools' id='debugTools' iconclass='category with-icon-paths snap-icon-debug_dual'>
            <label text="Debug Tools"/>
            { _.values(props.blocks).map((Block, id) => <Block.component context={props.context} key={id} />)}
        </category>
    )
};

export default Debug;