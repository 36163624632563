import React from 'react'
import _ from 'underscore';
import MyBlockSearch from "../MyBlocks/MyBlockSearch";

const MAX_LENGTH = 13;

const _search = (list, query) => {
    return query.split(' ').reduce((isMatched, queryPartial) => {
        isMatched = isMatched && !!_.filter(list, (item) => item.indexOf(queryPartial.toLowerCase()) === 0).length;
        return isMatched;
    } , true);
};

const _filterBlocks = (blocks, searchQuery, props) => {
    return _.keys(blocks).reduce((blockObj, blockKey) => {
        let _filteredBlocks = {};
        if (blocks[blockKey].childComponents) {
            _filteredBlocks = _filterBlocks(blocks[blockKey].childComponents, searchQuery, props);
        }
        if (_search(blocks[blockKey].searchTags, searchQuery) || _.values(_filteredBlocks).length) {
            blockObj = {
                ...blockObj,
                [blockKey]: {
                    component: blocks[blockKey].component,
                    childComponents: _filteredBlocks,
                    searchTags: blocks[blockKey].searchTags
                }
            };
        }
        return blockObj;
    }, {})
};

const Search = (props) => {
    const filteredToolboxList = _.keys(props.blocks).reduce((filteredToolbox, category) => {
        const _filteredBlocks = _filterBlocks(props.blocks[category], props.searchQuery, props);

        if (!_.isEmpty(_filteredBlocks)) {
            filteredToolbox = {
                ...filteredToolbox,
                [category]: _filterBlocks(props.blocks[category], props.searchQuery, props)
            };
        }

        return filteredToolbox;
    }, {});

    const filteredComponents = _.chain(filteredToolboxList)
        .keys()
        .map(category => {
            if (category === "My Libraries") {
                return <MyBlockSearch libraries={filteredToolboxList[category]}/>
            } else {
                let blocks = _.values(filteredToolboxList[category]);
                if (!blocks.length) { return }
                let renderComponents = [
                    <label text={category} web-class='search-category' key={category}/>,
                    ...blocks.map((Block, id) => <Block.component { ...props } childComponents={Block.childComponents} key={id} />)
                ];

                const isRendered = renderComponents.reduce((rendered, component, i) => {
                    if (i === 0) return rendered;
                    let renderedComponent;

                    try {
                        renderedComponent = component.type(component.props);
                    } catch (e) {
                        renderedComponent = new component.type(component.props);
                    }

                    // If component is nested like widget values, check the presence of the label (first element) *check render function of widget values
                    renderedComponent = _.isArray(renderedComponent) ? renderedComponent[0] : renderedComponent;
                    return !!(rendered || renderedComponent);
                }, false);

                return isRendered ? renderComponents : null
            }
        })
        .compact()
        .value();

    const { searchQuery: sq } = props;
    const searchLabel = _.isEmpty(filteredComponents)
                        ? 'No results found!'
                        : `Results for '${sq.substring(0, MAX_LENGTH)}${sq.length > MAX_LENGTH ? '...' : ''}'`;

    return <category name={`Search`} iconclass='category with-icon-paths snap-icon-search_dual'>
        <label text={ searchLabel } web-class='search-result-label' />
        { filteredComponents }
    </category>
};

export default Search;