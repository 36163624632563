/* global Blockly */
import React from 'react'


function defineBlock(context) {
    return {
        init: function() {
            let selectChartDropdown = new Blockly.FieldWidgetsDropdown(context, 'chart', undefined, '- select -');

            this.appendDummyInput('WIDGET')
                .appendField("refresh chart")
                .appendField(selectChartDropdown, "ID");
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setOutput(false);
            this.setPreviousStatement(true);
            this.setNextStatement(true);
            this.setTooltip("Refresh the selected chart.");
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            let id = block.getFieldValue('ID');
            let code = `Snap.widgets.chart.refresh("${id}");\n`;
            return code;
        }
    }
}

const RefreshChart = function (props) {
    let name = "snap_refresh_chart";
    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators(props.context);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }
    return <block type={name}></block>;
}

export default RefreshChart;