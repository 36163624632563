/* global Blockly */
import React from 'react'
import { xSightTopicIcon } from './index';

function defineBlock(context){
    let xSightTopicNames = (context.xSightTopics && context.xSightTopics.length) ? context.xSightTopics.map(x => [x.name, x.name]) : [['- select -', 'TopicName']];

    return {
        init: function() {
            this.appendDummyInput('WIDGET')
                .appendField('set')
                .appendField(new Blockly.FieldImage(xSightTopicIcon, 30, 30, "xSight icon", null), 'xSightIcon')
                .appendField(new Blockly.FieldDropdownSearch(xSightTopicNames, undefined, '- select -'), 'TOPIC')
                .appendField('to');
            
            this.appendValueInput('VALUE');

            this.setPreviousStatement(true);
            this.setNextStatement(true);
            this.setColour(Blockly.Msg.XSIGHT_TOPICS_HUE);
            this.setTooltip('Set the value of a Snap xSight topic.');
            this.setHelpUrl(Blockly.BASE_HELP_URL+"#variables");
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function(block){
            let topicName = block.getFieldValue('TOPIC');
            let value = Blockly.JavaScript.valueToCode(block,'VALUE', Blockly.JavaScript.ORDER_NONE);
            return 'Snap.xSightTopic.setValue("' + topicName + '",' + value + ');\n'
        }
    }
}

const SetXSightTopic = function (props){
    const name = 'snap_xsight_topic_set';

    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators();
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    return (
        <block type={name}>
            <value name="VALUE">
                <shadow type="text"/>
            </value>
        </block>
    )
}

export default SetXSightTopic
