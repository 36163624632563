import React from 'react'
import _ from 'underscore';
// AppActions, App Actions, app-actions, appActions

const AppActions = (props) => {

    if(!props.context){
        return null;
    }

    return (
        <category name='App Actions' id='appActions' iconclass='category with-icon-paths snap-icon-app_action_dual'>
            <label text="App Actions"/>,
            { _.values(props.blocks).map((Block, id) => <Block.component context={props.context} key={id}/>) }
        </category>
    )
};

export default AppActions;