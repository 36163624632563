/* global Blockly */
import React from 'react'
import { WORKFLOW_APP_TYPE_ID } from "../../../../utils";
import { userIcon } from './index';

function defineBlock(context) {
    return {
        init: function () {
            this.setOpacity(0.6);
            this.appendDummyInput().appendField('      - deprecated -');
            this.setWarningText("This block was deprecated, please use the \"Get user field\" block instead.");

            this.appendDummyInput('WIDGET')
                .appendField('get')
                .appendField(new Blockly.FieldImage(userIcon, 30, 30, "user icon", null), 'userIcon')
                .appendField(new Blockly.FieldVariable(),'VARIABLE')
                .appendField('system id')
                .setOnNewRow(true);

            this.appendStatementInput('CALLBACK');

            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Get the system id of a logged in user.');
            this.setHelpUrl(Blockly.BASE_HELP_URL + "#variables");
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            let variableName = Blockly.JavaScript.variableDB_.getName(block.getField('VARIABLE').getText(), Blockly.Variables.NAME_TYPE);
            let callback = Blockly.JavaScript.statementToCode(block, 'CALLBACK');
            return 'Snap.user.getUserSysId(function(' + variableName + ') {' + callback + '});\n';
        }
    }
}

const GetUserSystemId = function (props) {
    const name = 'snap_user_get_system_id';

    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators();
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    return props.context.appTypeId === WORKFLOW_APP_TYPE_ID ? <block type={name}></block> : null;
}

export default GetUserSystemId
