/* global Blockly */
/* global $ */
/* global Util */
const nacl = require('tweetnacl');
nacl.util = require('tweetnacl-util');

// contains all common functionality for REST-like blocks
const RestCore = function (prop) {
    const serverURL = process.env.REACT_APP_DASHBOARD_API_ROOT;
    const runTimeBlockList = ['variables_get', 'getter', 'snap_get_textbox_value', 'request_response_parameter',
                            'snap_get_label_text', 'snap_get_paragraph_text', 'snap_get_scanned_values_of_barcode_widget',
                            'snap_get_checkboxlist_selected_checkboxes', 'snap_get_date_value', 'snap_get_selected_value_of_dropdown',
                            'snap_get_value_of_selected_radiobutton', 'snap_get_rating_value', 'snap_get_environment_variable',
                            'snap_get_flipswitch_value', 'get_value_of_selected_externaldata_option', 'snap_get_uploaded_value',
                            'snap_get_uploaded_values', 'snap_get_image_source'];
    const unsupportedList = [/*'math_arithmetic','math_single', 'math_trig', 'math_constant', 'math_number_property',
                            'math_round', 'math_on_list', 'math_modulo', 'math_constrain', 'math_random_int',
                            'math_random_float', 'math_round_to_decimal', */
                            /*'text_append',
                            'text_length', 'soti_snackbar', 'text_isEmpty', 'text_indexOf', 'text_charAt',
                            'text_getSubstring', 'text_changeCase', 'text_trim', */
                            /*'lists_create_with',
                            'lists_repeat', 'lists_length', 'lists_isEmpty', 'lists_indexOf',
                            'lists_getIndex', 'lists_setIndex_2', 'lists_getSublist', 'lists_split',
                            'lists_sort', 'make_json_with', 'get_from_json', */
                            'colour_picker',
                            'colour_rgb', 'date', 'date_today', /*'get_day_from_date'*/, 'date_adder',
                            'snap_date_picker_number', 'date_is_before', 'logic_compare_new',
                            'tableview_selected_row',
                            'logic_negate', 'logic_operation'];
    const thickAdd = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='28px' height='28px' viewBox='0 0 28 28' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eicon-add-SNAP%3C/title%3E%3Cg id='icon-add-SNAP' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Ccircle id='Oval' stroke='%23E4E7E9' cx='14' cy='14' r='11.5'%3E%3C/circle%3E%3Cpath d='M14,3 C20.0751322,3 25,7.92486775 25,14 C25,20.0751322 20.0751322,25 14,25 C7.92486775,25 3,20.0751322 3,14 C3,7.92486775 7.92486775,3 14,3 Z M14.25,8 L13.75,8 C13.3357864,8 13,8.33578644 13,8.75 L13,8.75 L13,13 L8.75,13 C8.37030423,13 8.05650904,13.2821539 8.00684662,13.6482294 L8,13.75 L8,14.25 C8,14.6642136 8.33578644,15 8.75,15 L8.75,15 L13,15 L13,19.25 C13,19.6296958 13.2821539,19.943491 13.6482294,19.9931534 L13.75,20 L14.25,20 C14.6642136,20 15,19.6642136 15,19.25 L15,19.25 L15,15 L19.25,15 C19.6296958,15 19.943491,14.7178461 19.9931534,14.3517706 L20,14.25 L20,13.75 C20,13.3357864 19.6642136,13 19.25,13 L19.25,13 L15,13 L15,8.75 C15,8.37030423 14.7178461,8.05650904 14.3517706,8.00684662 L14.25,8 Z' id='Combined-Shape' fill='%23FFFFFF'%3E%3C/path%3E%3C/g%3E%3C/svg%3E";
    const thickRemove = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='28px' height='28px' viewBox='0 0 28 28' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eicon-remove-SNAP%3C/title%3E%3Cg id='icon-remove-SNAP' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Ccircle id='Oval' stroke='%23E4E7E9' cx='14' cy='14' r='11.5'%3E%3C/circle%3E%3Cpath d='M14,3 C20.0751322,3 25,7.92486775 25,14 C25,20.0751322 20.0751322,25 14,25 C7.92486775,25 3,20.0751322 3,14 C3,7.92486775 7.92486775,3 14,3 Z M18.75,12.5 L9.25,12.5 C8.55964406,12.5 8,13.0596441 8,13.75 L8,13.75 L8,14.25 C8,14.9403559 8.55964406,15.5 9.25,15.5 L9.25,15.5 L18.75,15.5 C19.4403559,15.5 20,14.9403559 20,14.25 L20,14.25 L20,13.75 C20,13.0596441 19.4403559,12.5 18.75,12.5 L18.75,12.5 Z' id='Combined-Shape' fill='%23FFFFFF'%3E%3C/path%3E%3C/g%3E%3C/svg%3E";
    const collapseImage = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjhweCIgaGVpZ2h0PSIyOHB4IiB2aWV3Qm94PSIwIDAgMjggMjgiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+aWNvbi1kcm9wZG93bi1TTkFQPC90aXRsZT4KICAgIDxnIGlkPSJpY29uLWRyb3Bkb3duLVNOQVAiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxjaXJjbGUgaWQ9Ik92YWwiIHN0cm9rZT0iI0U0RTdFOSIgY3g9IjE0IiBjeT0iMTQiIHI9IjExLjUiPjwvY2lyY2xlPgogICAgICAgIDxwYXRoIGQ9Ik0xNCwzIEMyMC4wNzUxMzIyLDMgMjUsNy45MjQ4Njc3NSAyNSwxNCBDMjUsMjAuMDc1MTMyMiAyMC4wNzUxMzIyLDI1IDE0LDI1IEM3LjkyNDg2Nzc1LDI1IDMsMjAuMDc1MTMyMiAzLDE0IEMzLDcuOTI0ODY3NzUgNy45MjQ4Njc3NSwzIDE0LDMgWiBNOS42MzYzNjM2NCwxMS4yODg3NzAxIEM5LjI1MTMzNjksMTAuOTAzNzQzMyA4LjYwOTYyNTY3LDEwLjkwMzc0MzMgOC4yODg3NzAwNSwxMS4yODg3NzAxIEw4LjI4ODc3MDA1LDExLjI4ODc3MDEgTDguMjA2NzUyNTIsMTEuMzgwODY0NSBDNy45MDYwMjE1OCwxMS43NjE1MSA3LjkzMzM2MDc2LDEyLjI4MDk1NDMgOC4yODg3NzAwNSwxMi42MzYzNjM2IEw4LjI4ODc3MDA1LDEyLjYzNjM2MzYgTDEzLjEwMTYwNDMsMTcuNDQ5MTk3OSBDMTMuNjE0OTczMywxNy45NjI1NjY4IDE0LjM4NTAyNjcsMTcuOTYyNTY2OCAxNC44OTgzOTU3LDE3LjQ0OTE5NzkgTDE0Ljg5ODM5NTcsMTcuNDQ5MTk3OSBMMTkuNzExMjI5OSwxMi42MzYzNjM2IEMyMC4wOTYyNTY3LDEyLjI1MTMzNjkgMjAuMDk2MjU2NywxMS42NzM3OTY4IDE5LjcxMTIyOTksMTEuMjg4NzcwMSBMMTkuNzExMjI5OSwxMS4yODg3NzAxIEwxOS42MTkxMzU1LDExLjIwNjc1MjUgQzE5LjIzODQ5LDEwLjkwNjAyMTYgMTguNzE5MDQ1NywxMC45MzMzNjA4IDE4LjM2MzYzNjQsMTEuMjg4NzcwMSBMMTguMzYzNjM2NCwxMS4yODg3NzAxIEwxNC40NDkxOTc5LDE1LjIwMzIwODYgQzE0LjE5MjUxMzQsMTUuNDU5ODkzIDEzLjgwNzQ4NjYsMTUuNDU5ODkzIDEzLjU1MDgwMjEsMTUuMjAzMjA4NiBMMTMuNTUwODAyMSwxNS4yMDMyMDg2IFoiIGlkPSJDb21iaW5lZC1TaGFwZSIgZmlsbD0iI0ZGRkZGRiI+PC9wYXRoPgogICAgPC9nPgo8L3N2Zz4=";
    const expandImage = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjhweCIgaGVpZ2h0PSIyOHB4IiB2aWV3Qm94PSIwIDAgMjggMjgiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+aWNvbi1kcm9wZG93bi1TTkFQLTAyPC90aXRsZT4KICAgIDxnIGlkPSJpY29uLWRyb3Bkb3duLVNOQVAtMDIiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxjaXJjbGUgaWQ9Ik92YWwiIHN0cm9rZT0iI0U0RTdFOSIgY3g9IjE0IiBjeT0iMTQiIHI9IjExLjUiPjwvY2lyY2xlPgogICAgICAgIDxwYXRoIGQ9Ik0xNCwzIEMyMC4wNzUxMzIyLDMgMjUsNy45MjQ4Njc3NSAyNSwxNCBDMjUsMjAuMDc1MTMyMiAyMC4wNzUxMzIyLDI1IDE0LDI1IEM3LjkyNDg2Nzc1LDI1IDMsMjAuMDc1MTMyMiAzLDE0IEMzLDcuOTI0ODY3NzUgNy45MjQ4Njc3NSwzIDE0LDMgWiBNOS42MzYzNjM2NCwxMS4yODg3NzAxIEM5LjI1MTMzNjksMTAuOTAzNzQzMyA4LjYwOTYyNTY3LDEwLjkwMzc0MzMgOC4yODg3NzAwNSwxMS4yODg3NzAxIEw4LjI4ODc3MDA1LDExLjI4ODc3MDEgTDguMjA2NzUyNTIsMTEuMzgwODY0NSBDNy45MDYwMjE1OCwxMS43NjE1MSA3LjkzMzM2MDc2LDEyLjI4MDk1NDMgOC4yODg3NzAwNSwxMi42MzYzNjM2IEw4LjI4ODc3MDA1LDEyLjYzNjM2MzYgTDEzLjEwMTYwNDMsMTcuNDQ5MTk3OSBDMTMuNjE0OTczMywxNy45NjI1NjY4IDE0LjM4NTAyNjcsMTcuOTYyNTY2OCAxNC44OTgzOTU3LDE3LjQ0OTE5NzkgTDE0Ljg5ODM5NTcsMTcuNDQ5MTk3OSBMMTkuNzExMjI5OSwxMi42MzYzNjM2IEMyMC4wOTYyNTY3LDEyLjI1MTMzNjkgMjAuMDk2MjU2NywxMS42NzM3OTY4IDE5LjcxMTIyOTksMTEuMjg4NzcwMSBMMTkuNzExMjI5OSwxMS4yODg3NzAxIEwxOS42MTkxMzU1LDExLjIwNjc1MjUgQzE5LjIzODQ5LDEwLjkwNjAyMTYgMTguNzE5MDQ1NywxMC45MzMzNjA4IDE4LjM2MzYzNjQsMTEuMjg4NzcwMSBMMTguMzYzNjM2NCwxMS4yODg3NzAxIEwxNC40NDkxOTc5LDE1LjIwMzIwODYgQzE0LjE5MjUxMzQsMTUuNDU5ODkzIDEzLjgwNzQ4NjYsMTUuNDU5ODkzIDEzLjU1MDgwMjEsMTUuMjAzMjA4NiBMMTMuNTUwODAyMSwxNS4yMDMyMDg2IFoiIGlkPSJDb21iaW5lZC1TaGFwZSIgZmlsbD0iI0ZGRkZGRiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTQuMDAwMDAwLCAxNC4wMDAwMDApIHJvdGF0ZSgtOTAuMDAwMDAwKSB0cmFuc2xhdGUoLTE0LjAwMDAwMCwgLTE0LjAwMDAwMCkgIj48L3BhdGg+CiAgICA8L2c+Cjwvc3ZnPg==";
    const promisesExecutionString = "BlocklyPromises.length && ExecutionRuntimeModule.Execute(BlocklyPromises);"

    // checks that the value is an encrypted value
    function check_encryption(text) {
        try {
            if (JSON.parse(text).cipher_text && JSON.parse(text).one_time_code) {
                return true;
            }
        } catch (e) {
            //unencrypted
            return false;
        }
    }

    // ecrypt message
    function encrypting(plain_text) {
        var server_publicKey = new Uint8Array([209, 206, 136, 17, 228, 200, 186, 71, 49, 238, 139, 246, 105, 224, 146, 212, 166, 177, 235, 170, 85, 190, 146, 177, 66, 176, 76, 128, 116, 223, 233, 118]);
        var agent_secretKey = new Uint8Array([25, 214, 24, 123, 105, 253, 185, 215, 197, 97, 150, 227, 23, 90, 53, 27, 149, 169, 246, 158, 186, 147, 85, 77, 184, 76, 113, 210, 2, 128, 180, 244]);
        const shared_key = nacl.box.before(server_publicKey, agent_secretKey);
        const one_time_code = nacl.randomBytes(24);

        //Getting the cipher text
        const cipher_text = nacl.box.after(
            nacl.util.decodeUTF8(plain_text),
            one_time_code,
            shared_key
        );

        //message to be transited.
        const message_encrypted = {cipher_text,one_time_code};
        return message_encrypted;
    }

    // builds javascript rest response function
    function getResponseSuccessStr(id, evalStr) {
        return `
            function(data, status, xhr) {
                var ResponseScope = ResponseScope || {};
                ResponseScope['${id}'] = data;
                ${evalStr}
                ${promisesExecutionString}
            }`;
    }

    function getResponseErrorStr(id, evalStr) {
        return `
            function(data, status, xhr) {
                var ResponseScope = ResponseScope || {};
                ResponseScope['${id}'] = {
                    message: data.statusText,
                    statusCode: data.status,
                };
                ${evalStr}
                ${promisesExecutionString}
            }`;
    }

    function getNativeOnSuccessStr(id, evalStr) {
        return `
            function(data, statusCode) {
                if (isJSON(data)) {data = JSON.parse(data);}
                var ResponseScope = ResponseScope || {};
                ResponseScope['${id}'] = data;
                ${evalStr}
                ${promisesExecutionString}
            }`;
    }

    function getNativeOnErrorStr(id, evalStr) {
        return `
            function(statusCode, textStatus, errorThrown) {
                var ResponseScope = ResponseScope || {};
                ResponseScope['${id}'] = {
                    message: textStatus,
                    statusCode: statusCode,
                };
                ${evalStr}
                ${promisesExecutionString}
            }`;
    }

    return {
        proxyToggle: true,
        username_encrypted: false,
        password_encrypted: false,
        username_value : '',
        password_value : '',
        tab: 'authentication',
        visible: true, // is current block expanded
        showOnError: false, // is "onError" block expanded
        responseJsonStructure: null, // last saved json structure obtained from "test" response and selected in "on response" block
        bodyContentType: "application/json",

        // customization
        coreColor: "#000000", 
        toggleOn: "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='28px' height='28px' viewBox='0 0 28 28' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eicon-toggle on-SNAP%3C/title%3E%3Cg id='icon-toggle-on-SNAP' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Crect id='Rectangle' fill='%238A1C57' x='2' y='8' width='24' height='12' rx='6'%3E%3C/rect%3E%3Ccircle id='Oval' fill='%23FFFFFF' cx='20' cy='14' r='4'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E",
        toggleOff: "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='28px' height='28px' viewBox='0 0 28 28' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eicon-toggle off-SNAP%3C/title%3E%3Cg id='icon-toggle-off-SNAP' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Crect id='Rectangle' fill-opacity='0.7' fill='%23FFFFFF' x='2' y='8' width='24' height='12' rx='6'%3E%3C/rect%3E%3Ccircle id='Oval' fill='%23B64D8B' cx='8' cy='14' r='4'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E",
        tabCol: { primary:'#b10069', secondary:'#000000', tertiary:'#FFFFFF', text:'#FFFFFF'},
        testButton: {primary:'#ffffff', secondary:'#000000', tertiary:'#C1C1C1', text:'#b10069'},
        disabledCol: {primary:'#ffffff', secondary:'#000000', tertiary:'#C1C1C1', text:'#b10069'},

        // init expand/collapse button
        initToggleButton: function() {
            this.appendDummyInput("TOGGLE_BTN")
                .setOnNewRow(true)
                .appendField(new Blockly.FieldImage(expandImage, 30, 30, "settings", function() {
                    this.switchVisibility(!this.visible);
                }.bind(this)));
            this.appendDummyInput('TOGGLE_BTN_PLACEHOLDER')
        },

        // init REST verb selector and url
        initRestUrl: function(onlyReadVerbs, setOnNewRow) {
            // create verb selector
            let verbs = [['GET','GET'], ['POST','POST']];
            if (!onlyReadVerbs) {
                verbs.push(['PUT','PUT']);
                verbs.push(['DELETE','DELETE']);
            }
            this.appendDummyInput('REST')
                .appendField('REST')
                .appendField(new Blockly.FieldDropdown(verbs), 'METHOD')
                .setOnNewRow(setOnNewRow);

            // create url text input
            this.appendValueInput('URL')
                .setOnNewRow(false)
                .setCheck("String");
            var urlField = this.getInput("URL");
            var urlBlock = this.workspace.newBlock("rest_url")
            urlBlock.setDisplayWidth(39);
            urlBlock.setShadow(true);
            urlField.connection.connect(urlBlock.outputConnection);
        },

        // init REST header tabs and their inner fields
        initHeaderTabs: function() {
            // block body: tabs selector
            this.appendDummyInput('TABS')
                .setOnNewRow(true)
                .appendField(new Blockly.FieldButton('Authentication',this.disabledCol,function(){this.setTab('authentication')}.bind(this)),'authentication')
                .appendField(new Blockly.FieldButton('Headers',this.disabledCol,function(){this.setTab('headers')}.bind(this)),'headers')
                .appendField(new Blockly.FieldButton('QueryString',this.disabledCol,function(){this.setTab('query strings')}.bind(this)),'query strings')
                .appendField(new Blockly.FieldButton('Data',this.disabledCol,function(){this.setTab('data')}.bind(this)),'data');

            // block body: authentication tab
            this.appendValueInput('USERNAME')
                .appendField('username ')
                .setOnNewRow(true)
                .setCheck("String")
                .setVisible(true);
            this.appendValueInput('PASSWORD')
                .appendField('password ')
                .setOnNewRow(true)
                .setCheck("String")
                .setVisible(true);

            // init "Update Credentials" button
            this.appendDummyInput('UPDATE')
                .appendField(new Blockly.FieldButton( 'Update Credentials', this.disabledCol, function(){
                    this.updateCredential();
                }.bind(this)),'UPDATE')
                .setOnNewRow(true)
                .setVisible(false);

            // block body: headers tab
            this.appendValueInput('HEADERS')
                .setAlign(Blockly.ALIGN_LEFT)
                .setOnNewRow(true)
                .setCheck("object")
                .setVisible(false);

            // block body: query string tab
            this.appendValueInput('QUERY_STRINGS')
                .setAlign(Blockly.ALIGN_LEFT)
                .setOnNewRow(true)
                .setCheck("object")
                .setVisible(false);

            // block body: data tab
            this.appendValueInput('DATA')
                .setAlign(Blockly.ALIGN_LEFT)
                .setOnNewRow(true)
                .setCheck("object")
                .setVisible(false);
            this.appendValueInput('RAWDATA')
                .setAlign(Blockly.ALIGN_LEFT)
                .setCheck("String")
                .setOnNewRow(true)
                .setVisible(false);
            this.appendDummyInput('DATA_FORMAT')
                .appendField(new Blockly.FieldDropdown([['JSON','JSON'],['form-data','form-data'],['x-www-form-urlencoded','x-www-form-urlencoded'],['RAW','RAW']],this.changeDataFormat.bind(this)),'DATA_FORMAT')
                .setVisible(false);
        },

        // init success/error blocks
        initRestResults: function() {
            // block body: proxy toggle
            this.appendDummyInput("TOGGLE_CALL")
                .appendField('Use Proxy ')
                .appendField(new Blockly.FieldImage(this.toggleOff, 55, 55, "toggleOff", function(){
                    this.switchDirectCall(!this.proxyToggle);
                }.bind(this)))
                .setAlign(Blockly.ALIGN_RIGHT);

            // on success parameter, table columns and test output
            this.appendValueInput('SUCCESS_PARAM')
                .appendField('on success ')
                .setOnNewRow(true);
            var successParam = this.getInput("SUCCESS_PARAM");
            successParam.connection.readOnly = "request_response_parameter";

            // on success block
            this.appendStatementInput('SUCCESS');
            this.appendDummyInput("ADD")
                .appendField(new Blockly.FieldImage(thickAdd, 24, 24, "*", function(){
                    this.addElse(!this.showOnError);
                }.bind(this)))
                .setVisible(false);

            // on error block
            this.appendValueInput('ERROR_PARAM')
                .appendField('on error ');
            var errorParam = this.getInput("ERROR_PARAM");
            errorParam.connection.readOnly = "request_error_response";
            this.appendDummyInput("MINUS")
                .appendField(new Blockly.FieldImage(thickRemove, 24, 24, "*", function(){
                    this.removeElse(!this.showOnError);
                }.bind(this)))
                .setAlign(Blockly.ALIGN_RIGHT);
            this.appendStatementInput('ERROR');
        },
        
        // adds/shows "onError" block
        addElse: function(showOnError) {
            this.showOnError = showOnError;
            this.setVisibleBetween(showOnError, 'SUCCESS', 'null');
            let errParam = this.getInput('ERROR_PARAM');
            if (!errParam.connection.isConnected()) {
                let block = this.createErrorResponseBlock(this.workspace);
                errParam.connection.connect(block.outputConnection)
            } else {
                errParam.connection.targetBlock().setParentId(this.id);
            }
            let plusButton = this.getInput('ADD');
            let minusButton = this.getInput('MINUS');
            plusButton.setVisible(!this.showOnError);
            minusButton.setVisible(this.showOnError);
        },

        // hides/removes "onError" block
        removeElse: function(showOnError) {
            this.showOnError = showOnError;
            let plusButton = this.getInput('ADD');
            let minusButton = this.getInput('MINUS');
            let errParam = this.getInput('ERROR_PARAM');
            let errStatement = this.getInput('ERROR');
            if (errParam.connection.isConnected()) {
                errParam.connection.targetBlock().dispose();
            }
            errParam.setVisible(this.showOnError);
            errStatement.setVisible(this.showOnError);
            plusButton.setVisible(!this.showOnError);
            minusButton.setVisible(this.showOnError);
        },

        // handles all change events in blockly
        changeHandlerCore: function(event) {
            if (!this.firstInit) {
                if(this.getInput('HEADERS').connection.targetBlock() && this.getInput('QUERY_STRINGS').connection.targetBlock() && this.getInput('DATA').connection.targetBlock()){
                    this.getInput('HEADERS').connection.targetBlock().setMovable(false);
                    this.getInput('QUERY_STRINGS').connection.targetBlock().setMovable(false);
                    this.getInput('DATA').connection.targetBlock().setMovable(false);
                    this.getInput('HEADERS').connection.targetBlock().getSvgRoot().style.display='none';
                    this.getInput('QUERY_STRINGS').connection.targetBlock().getSvgRoot().style.display='none';
                    this.getInput('DATA').connection.targetBlock().getSvgRoot().style.display='none';
                    this.firstInit = true;
                    // Re-render username and password field on init
                    this.switchVisibility(this.visible);
                    // Re-render else part on init
                    this.addElse(!this.showOnError);
                    this.removeElse(!this.showOnError);
                }
            }
            
            if (this.visible) {
                this.setBodyByMethod();
                if (this.username_encrypted && this.getInput('USERNAME').connection.targetBlock()) {
                    this.getInput('USERNAME').connection.targetBlock().getSvgRoot().style.display = 'none';
                    this.initSvg();
                    this.render();
                }
                if (this.password_encrypted && this.getInput('PASSWORD').connection.targetBlock()) {
                    this.getInput('PASSWORD').connection.targetBlock().getSvgRoot().style.display = 'none';
                    this.initSvg();
                    this.render();
                }
            }
            this.switchVisibility(this.visible);
            if (this.getInput('PASSWORD').connection.targetBlock()) {
                if (this.getInput('PASSWORD').connection.targetBlock().type == "password_text") {
                    if (this.getInput('PASSWORD').connection.targetBlock().inputList[0].fieldRow[0].value_ != "") {
                        this.getInput('PASSWORD').connection.targetBlock().getSvgRoot().getElementsByClassName("blocklyText")[0].style.contentVisibility = 'hidden';
                        this.getInput('PASSWORD').connection.targetBlock().getSvgRoot().getElementsByClassName("field-text-quote")[0].style.contentVisibility = 'hidden';
                        this.getInput('PASSWORD').connection.targetBlock().getSvgRoot().getElementsByClassName("field-text-quote")[1].style.contentVisibility = 'hidden';
                        this.buildPasswordIcon();
                    } else {
                        this.getInput('PASSWORD').connection.targetBlock().getSvgRoot().getElementsByClassName("blocklyText")[0].style.contentVisibility = 'auto';
                        this.removePasswordIcon();
                    }
                }
            }

            // auto-recreate success/error parameters when you drag one out
            let errParam = this.getInput('ERROR_PARAM');
            let successParam = this.getInput('SUCCESS_PARAM');
            if (!successParam.connection.isConnected()) {
                // make sure there is always onsuccess response block
                let block = this.createSuccessResponseBlock(this.workspace);
                successParam.connection.connect(block.outputConnection)
            } else {
                if (successParam.connection.targetBlock().type === 'request_response_parameter') {
                    successParam.connection.targetBlock().setParentId(this.id);
                    let newJsonStructure = successParam.connection.targetBlock().jsonStructure;
                    if (!(this.responseJsonStructure && Object.keys(this.responseJsonStructure.toString()) && (!newJsonStructure || Object.keys(newJsonStructure) == 0))) {
                        // do not overwrite existing structure with empty/null
                        this.responseJsonStructure = newJsonStructure || {};
                    }
                }
            }
            if (this.showOnError) {
                if (!errParam.connection.isConnected()) {
                    // make sure there is always onerror response block
                    let block = this.createErrorResponseBlock(this.workspace);
                    errParam.connection.connect(block.outputConnection)
                } else {
                    if (errParam.connection.targetBlock().type === 'request_error_response') {
                        errParam.connection.targetBlock().setParentId(this.id);
                    }
                }
            }
        },

        // change rest method, modify visible block body elements
        setBodyByMethod: function() {
            var queryString = this.getField('query strings');
            var data = this.getField('data');
            var tab = this.tab;
            if (this.getFieldValue('METHOD') == 'GET') {
                queryString.setVisible(true);
                data.setVisible(false);
                if (tab === 'data') {
                    this.setTab('query strings');
                }
                this.initSvg();
                this.render();
            } else {
                data.setVisible(true);
                queryString.setVisible(false);
                if (tab === 'query strings') {
                    this.setTab('data');
                }
                this.initSvg();
                this.render();
            }
        },

        // change the header tab
        setTab: function(tab) {
            this.tab = tab;
            this.setVisibleBetween(false,'TABS','TEST');
            if (this.visible) {
                if (tab === 'authentication') {
                    this.setVisibleBetween(true, 'TABS', 'HEADERS');
                    this.getField('headers').setColour(this.disabledCol);
                    this.getField('query strings').setColour(this.disabledCol);
                    this.getField('data').setColour(this.disabledCol);
                    if (this.username_encrypted || this.password_encrypted) {
                        let username = 'unencrypted';
                        let password = 'unencrypted';
                        if (this.username_encrypted) {
                            username = 'USERNAME';
                            this.getInput('USERNAME').connection.targetBlock().getSvgRoot().style.display = 'none';
                        }
                        if (this.password_encrypted) {
                            password = 'PASSWORD';
                            this.getInput('PASSWORD').connection.targetBlock().getSvgRoot().style.display = 'none';
                        }
                        this.setVisibleForCredential(false, username, password);
                    } else {
                        this.setInputVisibility(this.getInput('UPDATE'),false);
                    }
                } else if (tab === 'headers') {
                    this.setVisibleBetween(true, 'UPDATE', 'QUERY_STRINGS');
                    this.getField('query strings').setColour(this.disabledCol);
                    this.getField('authentication').setColour(this.disabledCol);
                    this.getField('data').setColour(this.disabledCol);
                } else if (tab === 'query strings') {
                    this.setVisibleBetween(true, 'HEADERS', 'DATA');
                    this.getField('headers').setColour(this.disabledCol);
                    this.getField('authentication').setColour(this.disabledCol);
                    this.getField('data').setColour(this.disabledCol);
                } else if (tab === 'data') {
                    this.setVisibleBetween(true, 'QUERY_STRINGS', 'TEST');
                    this.getField('headers').setColour(this.disabledCol);
                    this.getField('authentication').setColour(this.disabledCol);
                    this.getField('query strings').setColour(this.disabledCol);
                    this.changeDataFormat();
                }
                this.setBodyByMethod();
            } else {
                this.switchVisibility(this.visible);
            }
            this.getField(tab).setColour(undefined);
        },

        // clicking on expand/collapse button shows/hides block body (authentication, headers, query string) and columns
        switchVisibility: function (visible) {
            this.visible = visible;
            this.setVisibleBetween(visible, 'URL', 'SUCCESS_PARAM');
            this.removeInput("TOGGLE_BTN");
            var image = this.visible ? collapseImage : expandImage;
            this.appendDummyInput("TOGGLE_BTN")
                .appendField(new Blockly.FieldImage(image, 30, 30, "settings", function() {
                    this.switchVisibility(!this.visible);
                }.bind(this)));
            if (this.visible) {
                // change TEST button to be more rounded shape
                // this.getField('TEST').getSvgRoot().getElementsByClassName('blocklyBlockBackground')[0].style.rx = 15;
                // this.getField('TEST').getSvgRoot().getElementsByClassName('blocklyBlockBackground')[0].style.ry = 15;
                this.setTab(this.tab);
                //hide TEST button based on proxyToggle toggle
                this.switchDirectCall(this.proxyToggle);
            }
            
            this.moveInputBefore("TOGGLE_BTN", "TOGGLE_BTN_PLACEHOLDER"); 

            if (this.switchVisibilityOuter)
                this.switchVisibilityOuter(visible);
            this.removeSelect();
            this.initSvg();
            this.render();
        },

        // mark fields between first and last to have specified visibility
        setVisibleBetween: function(visible, first, last){
            let toHide = [];
            let startHiding = false;
            for(let i in this.inputList){
                if(this.inputList[i].name===last) break;
                if(startHiding){
                    toHide.push(this.inputList[i])
                } else if (this.inputList[i].name===first){
                    startHiding = true;
                }
            }
            toHide.forEach(x => this.setInputVisibility(x, visible));
            this.render();
        },

        // set input's visibility, including inner connections
        setInputVisibility(input, visible) {
            input.setVisible(visible);
            // @ idk why this is necessary/why it works...
            // if you don't have it then when you switch tabs and then switch back, rendering of text blocks
            // in the kvp of query strings/headers don't resize the block properly (eg. when entering a lot of text)
            if (input.connection && input.connection.targetBlock()) {
                input.connection.targetBlock().initSvg();
                input.connection.targetBlock().render();
            }
        },

        // change passowrd/username visibility if the value is decrypted
        setVisibleForCredential: function(visible, username, password) {
            let toHide = [];
            for (let i in this.inputList) {
                if (this.inputList[i].name === username) {
                    toHide.push(this.inputList[i])
                }
                if (this.inputList[i].name === password) {
                    toHide.push(this.inputList[i])
                }
            }

            toHide.forEach(x => this.setInputVisibility(x, visible));

            this.initSvg();
            this.render();
        },

        // update credential
        updateCredential: function() {
            //save username and password
            var usernameInput = this.getInput('USERNAME').connection.targetBlock();
            var passwordInput = this.getInput('PASSWORD').connection.targetBlock();
            if (usernameInput && usernameInput.inputList[0].fieldRow[0].value_) {
                this.username_value = usernameInput.inputList[0].fieldRow[0].value_
                usernameInput.inputList[0].fieldRow[0].setText('');
                usernameInput.inputList[0].fieldRow[0].value_ = '';
            }
            if (passwordInput && passwordInput.inputList[0].fieldRow[0].value_) {
                this.password_value = passwordInput.inputList[0].fieldRow[0].value_
                passwordInput.inputList[0].fieldRow[0].setText('');
                passwordInput.inputList[0].fieldRow[0].value_ = '';
                this.removePasswordIcon();
            }

            //set username and password visible
            usernameInput.getSvgRoot().style.display = '';
            passwordInput.getSvgRoot().style.display = '';
            this.setVisibleForCredential(true, 'USERNAME', 'PASSWORD');
            this.username_encrypted = false;
            this.password_encrypted = false;

            //add cancel button
            this.addCancelUpdateButton();

            //hide update button
            this.setInputVisibility(this.getInput('UPDATE'),false);

            this.initSvg();
            this.render();
            this.workspace.render();

        },

        // cancel update credential
        cancelUpdateCredential: function () {
            var usernameInput = this.getInput('USERNAME').connection.targetBlock();
            var passwordInput = this.getInput('PASSWORD').connection.targetBlock();
            //cancel update, resume previous username and password
            usernameInput.inputList[0].fieldRow[0].value_ = this.username_value;
            passwordInput.inputList[0].fieldRow[0].value_ = this.password_value;

            //hide username and password
            usernameInput.getSvgRoot().style.display = 'none';
            passwordInput.getSvgRoot().style.display = 'none';
            this.setVisibleForCredential(false, 'USERNAME', 'PASSWORD');
            this.username_encrypted = true;
            this.password_encrypted = true;

            //remove cancel button
            this.removeInput("CANCEL");

            //show update button
            this.setInputVisibility(this.getInput('UPDATE'),true);

            this.initSvg();
            this.render();
            this.workspace.render();
        },

        addCancelUpdateButton: function () {
            this.appendDummyInput('CANCEL')
                .appendField(new Blockly.FieldButton( 'Cancel Update', this.disabledCol, function(){
                    this.cancelUpdateCredential();
                }.bind(this)),'CANCEL');
            this.moveInputBefore("CANCEL", "UPDATE")
        },

        changeDataFormat: function () {
            let dataFormat = this.getFieldValue("DATA_FORMAT");
            if (dataFormat === "RAW") {
                this.setInputVisibility(this.getInput('DATA'),false);
                this.setInputVisibility(this.getInput('RAWDATA'),true);
                this.bodyContentType = "text/plain";
            } else {
                this.setInputVisibility(this.getInput('DATA'),true);
                this.setInputVisibility(this.getInput('RAWDATA'),false);
                if (dataFormat === "x-www-form-urlencoded") {
                    this.bodyContentType = "application/x-www-form-urlencoded";
                }
            }
        },

        // toggle proxy button
        switchDirectCall: function (toggle) {
            this.proxyToggle = toggle;
            this.removeInput("TOGGLE_CALL");
            let image = toggle ? this.toggleOn : this.toggleOff;
            this.appendDummyInput("TOGGLE_CALL")
                .appendField('Use Proxy ')
                .appendField(new Blockly.FieldImage(image, 55, 55, "toggleOn", function() {
                    this.switchDirectCall(!this.proxyToggle);
                }.bind(this)))
                .setAlign(Blockly.ALIGN_RIGHT);
            this.moveInputBefore("TOGGLE_CALL", "SUCCESS_PARAM")
            this.removeSelect();
            this.initSvg();
            this.render();
        },

        // loading state data
        domToMutationCore: function(xml) {
            // load username/password
            let username_encrypted = xml.getAttribute("username");
            let password_encrypted = xml.getAttribute("password");
            let username = 'unencrypted';
            let password = 'unencrypted';
            if (eval(username_encrypted)) {
                username = 'USERNAME';
                this.username_encrypted = true;
            }
            if (eval(password_encrypted)) {
                password = 'PASSWORD';
                this.password_encrypted = true;
            }
            this.setVisibleForCredential(false,username,password);

            // check for old toggle
            let directAPICall = xml.getAttribute("directapicall");
            if (directAPICall !== null && directAPICall !== undefined) {
                //backwards compatibility support for directAPICall
                if (directAPICall == "false") {
                    this.proxyToggle = false;
                }
                this.switchDirectCall(!this.proxyToggle);
            } else {
                let proxyToggle = xml.getAttribute("proxytoggle");
                if (proxyToggle == "false") {
                    this.proxyToggle = true;
                } else {
                    this.proxyToggle = false;
                }
                this.switchDirectCall(!this.proxyToggle);
            }

            //This is for handle Blockly Gesture Drag
            let showCancelUpdateButton = xml.getAttribute("showCancelUpdateButton");
            if (showCancelUpdateButton !== null && showCancelUpdateButton !== undefined && showCancelUpdateButton == "true") {
                this.addCancelUpdateButton();
            }
        },

        // saving state data
        mutationToDomCore: function() {
            let username_encrypted = false;
            let password_encrypted = false;
            let showCancelUpdateButton = false;
            if (this.getInput('USERNAME').connection.targetBlock() && this.getInput('USERNAME').connection.targetBlock().inputList[0].fieldRow[0].value_) {
                username_encrypted = check_encryption(this.getInput('USERNAME').connection.targetBlock().inputList[0].fieldRow[0].value_)
            }
            if (this.getInput('PASSWORD').connection.targetBlock() && this.getInput('PASSWORD').connection.targetBlock().inputList[0].fieldRow[0].value_) {
                password_encrypted = check_encryption(this.getInput('PASSWORD').connection.targetBlock().inputList[0].fieldRow[0].value_)
            }
            if (this.getInput("CANCEL")) showCancelUpdateButton = true;

            return { 
                username: username_encrypted, 
                password: password_encrypted, 
                proxyToggle: this.proxyToggle,
                showCancelUpdateButton : showCancelUpdateButton
            };
        },

        // remove icons from password field
        removePasswordIcon: function (){
            let length = this.getInput('PASSWORD').connection.targetBlock().getSvgRoot().children.length;
            let parentNode = this.getInput('PASSWORD').connection.targetBlock().getSvgRoot();
            while (length > 4) {
                this.getInput('PASSWORD').connection.targetBlock().getSvgRoot().removeChild(parentNode.lastElementChild);
                length = this.getInput('PASSWORD').connection.targetBlock().getSvgRoot().children.length;
            }
        },

        // create icons for password fields
        buildPasswordIcon: function () {
            const newElement1 = document.createElementNS("http://www.w3.org/2000/svg", 'path');
            newElement1.setAttribute("class", "passwordIcon1")
            newElement1.setAttribute("d", "M 3 1.5 C 3 2.328125 2.328125 3 1.5 3 C 0.671875 3 0 2.328125 0 1.5 C 0 0.671875 0.671875 0 1.5 0 C 2.328125 0 3 0.671875 3 1.5 Z M 3 1.5 ");
            newElement1.style.stroke = "none";
            newElement1.style.fillRule = "evenodd";
            newElement1.style.fill = "rgb(0%,0%,0%)";
            newElement1.style.fillOpacity = 1;
            newElement1.style.transform = "translate(9px,16px)";
            this.getInput('PASSWORD').connection.targetBlock().getSvgRoot().appendChild(newElement1);

            //2
            const newElement2 = document.createElementNS("http://www.w3.org/2000/svg", 'path');
            newElement2.setAttribute("class", "passwordIcon2")
            newElement2.setAttribute("d", "M 8 1.5 C 8 2.328125 7.328125 3 6.5 3 C 5.671875 3 5 2.328125 5 1.5 C 5 0.671875 5.671875 0 6.5 0 C 7.328125 0 8 0.671875 8 1.5 Z M 8 1.5 ");
            newElement2.style.stroke = "none";
            newElement2.style.fillRule = "evenodd";
            newElement2.style.fill = "rgb(0%,0%,0%)";
            newElement2.style.fillOpacity = 1;
            newElement2.style.transform = "translate(9px,16px)";
            this.getInput('PASSWORD').connection.targetBlock().getSvgRoot().appendChild(newElement2);

            //3
            const newElement3 = document.createElementNS("http://www.w3.org/2000/svg", 'path');
            newElement3.setAttribute("class", "passwordIcon3")
            newElement3.setAttribute("d", "M 13 1.5 C 13 2.328125 12.328125 3 11.5 3 C 10.671875 3 10 2.328125 10 1.5 C 10 0.671875 10.671875 0 11.5 0 C 12.328125 0 13 0.671875 13 1.5 Z M 13 1.5 ");
            newElement3.style.stroke = "none";
            newElement3.style.fillRule = "evenodd";
            newElement3.style.fill = "rgb(0%,0%,0%)";
            newElement3.style.fillOpacity = 1;
            newElement3.style.transform = "translate(9px,16px)";
            this.getInput('PASSWORD').connection.targetBlock().getSvgRoot().appendChild(newElement3);

            //4
            const newElement4 = document.createElementNS("http://www.w3.org/2000/svg", 'path');
            newElement4.setAttribute("class", "passwordIcon4")
            newElement4.setAttribute("d", "M 18 1.5 C 18 2.328125 17.328125 3 16.5 3 C 15.671875 3 15 2.328125 15 1.5 C 15 0.671875 15.671875 0 16.5 0 C 17.328125 0 18 0.671875 18 1.5 Z M 18 1.5 ");
            newElement4.style.stroke = "none";
            newElement4.style.fillRule = "evenodd";
            newElement4.style.fill = "rgb(0%,0%,0%)";
            newElement4.style.fillOpacity = 1;
            newElement4.style.transform = "translate(9px,16px)";
            this.getInput('PASSWORD').connection.targetBlock().getSvgRoot().appendChild(newElement4);

            //5
            const newElement5 = document.createElementNS("http://www.w3.org/2000/svg", 'path');
            newElement5.setAttribute("class", "passwordIcon5")
            newElement5.setAttribute("d", "M 23 1.5 C 23 2.328125 22.328125 3 21.5 3 C 20.671875 3 20 2.328125 20 1.5 C 20 0.671875 20.671875 0 21.5 0 C 22.328125 0 23 0.671875 23 1.5 Z M 23 1.5 ");
            newElement5.style.stroke = "none";
            newElement5.style.fillRule = "evenodd";
            newElement5.style.fill = "rgb(0%,0%,0%)";
            newElement5.style.fillOpacity = 1;
            newElement5.style.transform = "translate(9px,16px)";
            this.getInput('PASSWORD').connection.targetBlock().getSvgRoot().appendChild(newElement5);
        },

        // validate inner field
        validateBlock: function(block) {
            var l =[];
            //var isValid = block.type !== 'variables_get' && block.type !== 'getter' && block.type !== 'snap_get_textbox_value' && block.type !== 'request_response_parameter';
            var isValid = !runTimeBlockList.includes(block.type);
            l.push(isValid);

            if(!isValid){
                block.setWarningText('This value can only be known at runtime. Please substitute this for a different block for testing your API call');
                var blockOnchange = block.onchange;
                block.setOnChange(function(x){
                    if(x.blockId===block.id && (x.element !=='warningOpen')){
                        block.setWarningText(false);
                        block.setOnChange(blockOnchange);
                    }
                });
            }

            block.getChildren().forEach(child => l.push(this.validateBlock(child)));
            return l.reduce(function(acc, cv){return acc&&cv});
        },

        // validate inner field for executing "test" request
        validateBlockForTest: function(block){
            var l =[];
            //var isValid = block.type !== 'variables_get' && block.type !== 'getter' && block.type !== 'snap_get_textbox_value' && block.type !== 'request_response_parameter';
            var isValid = !unsupportedList.includes(block.type);
            l.push(isValid);

            if(!isValid){
                block.setWarningText('Test function does not support this block. Please substitute this for a different block for testing your API call');
                var blockOnchange = block.onchange;
                block.setOnChange(function(x){
                    if(x.blockId===block.id && (x.element !=='warningOpen')){
                        block.setWarningText(false);
                        block.setOnChange(blockOnchange);
                    }
                });
            }

            block.getChildren().forEach(child => l.push(this.validateBlockForTest(child)));
            return l.reduce(function(acc, cv){return acc&&cv});
        },
        validateInputFieldsSyntax: function() {
            try {
                JSON.parse(Blockly.JavaScript.valueToCode(this,'QUERY_STRINGS',Blockly.JavaScript.ORDER_NONE));
            } catch (e) {
                this.setWarningSignForBlock('QUERY_STRINGS',e);
                return false;
            }
            try {
                JSON.parse(Blockly.JavaScript.valueToCode(this,'DATA',Blockly.JavaScript.ORDER_NONE));
            } catch (e) {
                this.setWarningSignForBlock('DATA',e);
                return false;
            }
            try {
                JSON.parse(Blockly.JavaScript.valueToCode(this,'HEADERS',Blockly.JavaScript.ORDER_NONE));
            } catch (e) {
                this.setWarningSignForBlock('HEADERS',e);
                return false;
            }
            try {
                eval(Blockly.JavaScript.valueToCode(this,'URL', Blockly.JavaScript.ORDER_NONE));
            } catch (e) {
                this.setWarningSignForBlock('URL',e);
                return false;
            }
            try {
                eval(Blockly.JavaScript.valueToCode(this,'RAWDATA',Blockly.JavaScript.ORDER_NONE));
            } catch (e) {
                this.setWarningSignForBlock('RAWDATA',e);
                return false;
            }
            return true;
        },
        setWarningSignForBlock: function(field, e) {
            var block = this.getInput(field).connection.targetBlock()
            block.setWarningText(`Syntax error: ${e}`);
            var blockOnchange = block.onchange;
            block.setOnChange(function(x){
                if(x.blockId===block.id && (x.element !=='warningOpen')){
                    block.setWarningText(false);
                    block.setOnChange(blockOnchange);
                }
            });
        },
        // generate html for testing connection
        getTestDom: function(requestSpec, callback, defaultMessage) {
            // create artificial output container
            $('.custom-rest-output').remove();
            var holder = $('.blocklyWidgetDiv');
            var restOutputContainer = $(`
                <div class='custom-rest-output'>
                    <div class="rest-service-test"> 
                        <div class='responseUrl'></div>
                        <input class="rest-test" id="testButton" type="button" value="test" style="display: none;">
                        <textarea class="responseArea" placeholder="Paste Schema"></textarea>
                    <div class='rest-control'>
                        <button class="rest-close" type="button" value="close">Close</button>
                        <button class="rest-done disabled" type="button" value="apply" disabled>Apply</button>
                        <button class="rest-queryurl" type="button" value="queryurl">Get RESPONSE FROM URL</button>
                        <button class="rest-copy" type="button" value="copy"><i></i><span>Copy</span></button>
                    </div>
                </div>`);
            holder.after(restOutputContainer);

            // render and define parameters
            var responseArea = restOutputContainer.find('.responseArea');
            var done = restOutputContainer.find('.rest-done');
            var querylButton = restOutputContainer.find(".rest-queryurl");
            var restCopyButton = restOutputContainer.find('.rest-copy');
            var responseData = null;
            var title = "Input a sample JSON response here to set the schema"
            var errorMessage = "Invalid JSON. Please verify the input schema"
            Util.resizeTestDom(this.workspace.scale);

            //define title
            restOutputContainer.find(".responseUrl").text(title);

            // manual close
            restOutputContainer.find('.rest-close').on('click',function() {
                callback();
            });

            // copy response data to clipboard
            restCopyButton.on('click', () => {
                restCopyButton.addClass("disabled");
                this.copyToClipboard(responseArea.val(), function(result, err) {
                    restCopyButton.find("span").text("Copied");
                    setTimeout(function() {
                        restCopyButton.removeClass("disabled");
                        restCopyButton.find("span").text("Copy");
                    }, 3000);
                });
            });

            done.on('click', function() {
                callback(responseData);
            })

            var schema = null;
            responseArea.on('input',function () {
                var restDoneButton = restOutputContainer.find('.rest-done');
                schema = responseArea.val().trim();
                if (schema !== '') {
                    try {
                        responseData = JSON.parse(schema);
                        restDoneButton.removeAttr('disabled').removeClass("disabled").css("backgroundColor", "rgb(30, 173, 138)");
                        restOutputContainer.find(".responseUrl").text(title);
                    } catch (e) {
                        restDoneButton.attr('disabled', 'disabled').addClass('disabled').css("backgroundColor", "rgb(67, 137, 255)");
                        restOutputContainer.find(".responseUrl").text(errorMessage);
                    }
                } else {
                    restDoneButton.attr('disabled', 'disabled').addClass('disabled').css("backgroundColor", "rgb(67, 137, 255)");
                }
            });


            querylButton.on('click',function(){
                // TODO move this into QUERY URL init request processing
                if (requestSpec) {
                    var url = JSON.parse(requestSpec.data).requestUrl;
                    var method = JSON.parse(requestSpec.data).method;
                    restOutputContainer.find(".responseUrl").text(`URL: ${method} ${url}`);
                    var testButton = restOutputContainer.find('#testButton');

                    requestSpec.success = function(data) {
                        // show response data on success
                        responseArea.val(JSON.stringify(data, null, 2));
                        responseData = data;
                        done.removeAttr('disabled').removeClass("disabled");
                        done.css("backgroundColor", "rgb(30, 173, 138)");
                    };
                    requestSpec.error = function(xhrErr, statusErr, errorMessage){
                        // show error data
                        var errStr = 'An error occurred: ' + xhrErr.status;
                        if(errorMessage)
                            errStr += ' - ' + errorMessage;
                        responseArea.val(errStr);
                    };
                    testButton.on('click', function() {
                        testButton.attr("disabled", true);
                        setTimeout(function() {
                            if (testButton);
                            testButton.attr("disabled", false);
                        }, 5000);
                        // launch request
                        $.ajax(requestSpec);
                    });

                    // auto-launch test on load
                    testButton.click();
                } else {
                    if (defaultMessage)
                        responseArea.val(defaultMessage);
                }
            });
        },

        // copy provided text to clipboard
        copyToClipboard: function(text, callback) {
            var _callbackFunction = function(result, err) {
                if (callback)
                    callback(result, err);
            }
          
            var _fallback = function() {
              // old way (execCommand copy)
              var textArea = document.createElement("textarea");
              textArea.value = text;
              textArea.style = "top: 0px; left: 0px; position: fixed; opacity: 0;"; // avoid scrolling to bottom
              document.body.appendChild(textArea);
              textArea.focus();
              textArea.select();
              try {
                  var successful = document.execCommand('copy');
                  if (!successful)
                      console.error('Could not copy text (execCommand): no error');
                  _callbackFunction(successful);
              } 
              catch (err) {
                  console.error('Could not copy text (execCommand): ', err);
                  _callbackFunction(false, err);
              }
              // cleanup
              document.body.removeChild(textArea);
            }
          
            // modern way (async clipboard api)
            if (navigator.clipboard) {
                navigator.clipboard.writeText(text).then(function() {
                  _callbackFunction(true);
                }, function(err) {
                  // either it is http or no permission was granted
                  console.error('Could not copy text (clipboard api): ', err);
                  _fallback();
                });
                return;
            }
            
            // fallbak to old way
            _fallback();
        },

        // open test page holder, runs request and send response data to handler
        openTestPageCore: function(context, successHandler) {
            Blockly.WidgetDiv.hide();
            Blockly.WidgetDiv.show(this);

            let validateResult = this.validateRequestSpec(context);
            // create test html object
            let spec = validateResult.requestSpec ? validateResult.requestSpec.spec : null;
            this.getTestDom(spec, function(responseData) {
                // called when "cancel" or "apply" button is clicked
                Blockly.WidgetDiv.hide();
                $('.custom-rest-output').hide();
                if (responseData) {
                    // "apply" is clicked after data is loaded
                    this.responseJsonStructure = responseData;
                    var block = this.createSuccessResponseBlock();
                    var successParamInput = this.getInput('SUCCESS_PARAM');
                    successParamInput.connection.targetBlock().dispose();
                    successParamInput.connection.connect(block.outputConnection);
                }
                if (successHandler)
                    successHandler(responseData);
            }.bind(this), validateResult.domMessage);
            
            Blockly.WidgetDiv.hide();
        },
        
        // creates "response data" block for onSuccess
        createSuccessResponseBlock: function(ws) {
            ws = ws || this.workspace;
            let jsonBlock = ws.newBlock('request_response_parameter');
            jsonBlock.setLabel('response data');
            jsonBlock.setParentId(this.id);
            jsonBlock.setJsonStructure(this.responseJsonStructure || {});
            if (this.rendered) {
                jsonBlock.initSvg();
                jsonBlock.render();
            }
            return jsonBlock;
        },

        // creates "error data" block for onError
        createErrorResponseBlock: function(ws){
            ws = ws || this.workspace;
            let jsonBlock = ws.newBlock('request_error_response');
            jsonBlock.setLabel('error data');
            jsonBlock.setParentId(this.id);
            jsonBlock.setJsonStructure({'message':'','statusCode':''});
            if (this.rendered) {
                jsonBlock.initSvg();
                jsonBlock.render();
            }
            return jsonBlock;
        },

        //set rest block error message
        setRestErrorMessage: function (errMsg) {
            prop.setRestBlockError(errMsg);
        },

        // Function to check if a string is valid JSON (array or object)
        isValidJSON: function (str) {
            try {
                var parsed = JSON.parse(str);
                return parsed !== null && (Array.isArray(parsed) || typeof parsed === 'object');
            } catch (e) {
                return false;
            }
        },

        //verify input blocks for connection test
        validateRequestSpec: function (context) {
            // Necessary to make sure variableDb is initialized
            Blockly.JavaScript.init(Blockly.mainWorkspace);
            let headersValidated = this.validateBlock(this.getInput('HEADERS').connection.targetBlock());
            let queryValidated = this.validateBlock(this.getInput('QUERY_STRINGS').connection.targetBlock());
            let dataValidated = this.validateBlock(this.getInput('DATA').connection.targetBlock());
            let urlValidated = this.validateBlock(this.getInput('URL').connection.targetBlock());
            let passwordValidated = this.validateBlock(this.getInput('PASSWORD').connection.targetBlock());
            let usernameValidated = this.validateBlock(this.getInput('USERNAME').connection.targetBlock());
            let rawRequestDateValidated = this.validateBlock(this.getInput('RAWDATA').connection.targetBlock());

            // Check if test function support this block
            let headersValidatedForTest = this.validateBlockForTest(this.getInput('HEADERS').connection.targetBlock());
            let queryValidatedForTest = this.validateBlockForTest(this.getInput('QUERY_STRINGS').connection.targetBlock());
            let dataValidatedForTest = this.validateBlockForTest(this.getInput('DATA').connection.targetBlock());
            let urlValidatedForTest = this.validateBlockForTest(this.getInput('URL').connection.targetBlock());
            let passwordValidatedForTest = this.validateBlockForTest(this.getInput('PASSWORD').connection.targetBlock());
            let usernameValidatedForTest = this.validateBlockForTest(this.getInput('USERNAME').connection.targetBlock());
            let rawRequestDateValidatedForTest = this.validateBlock(this.getInput('RAWDATA').connection.targetBlock());
            let readyForTest = headersValidatedForTest && queryValidatedForTest && dataValidatedForTest && urlValidatedForTest && passwordValidatedForTest && usernameValidatedForTest && rawRequestDateValidatedForTest;

            // Check syntax for input fields
            let inputSyntaxErrorFree = true;
            if(headersValidated && queryValidated && dataValidated && urlValidated && passwordValidated && usernameValidated && rawRequestDateValidated && readyForTest) {
                inputSyntaxErrorFree = this.validateInputFieldsSyntax();
            }

            let requestSpec = null;
            let domMessage = null;
            if(headersValidated && queryValidated && dataValidated && urlValidated && passwordValidated && usernameValidated && rawRequestDateValidated && readyForTest && inputSyntaxErrorFree) {
                // get connection data
                requestSpec = this.getRequestSpec(context);
            }
            else {
                // show error message
                if (!(headersValidated && queryValidated && dataValidated && urlValidated && passwordValidated && usernameValidated && rawRequestDateValidated && readyForTest)) {
                    domMessage = 'Some values or blocks used in your REST configuration are not supported or can only be known at runtime. Please provide default values for blocks with warnings.';
                } else {
                    domMessage = 'Some values or blocks used in your REST configuration have syntax error. Please check values or blocks with warning signs.';
                }

            }
            return {requestSpec,domMessage};
        },

        getRawTypeData :function () {
            var rawTypeData = Blockly.JavaScript.valueToCode(this, 'RAWDATA', Blockly.JavaScript.ORDER_NONE);
            if (this.validateBlock(this.getInput('RAWDATA').connection.targetBlock())) {
                //eval and get the output of text_join block before adding into value list
                let targetBlock = this.getInput('RAWDATA').connection.targetBlock();
                let targetBlockType = this.getInput('RAWDATA').connection.targetBlock().type;
                if (["text_join", "make_json_with", "lists_create_with"].includes(targetBlockType)) {
                    try {
                        rawTypeData = JSON.stringify(eval(rawTypeData));
                        this.setRestErrorMessage(false);
                    } catch (e) {
                        this.setRestErrorMessage("Rest block does not support one or more of the input blocks. Please substitute these blocks for a local variable block.");
                        targetBlock.setWarningText('Rest block does not support this block. Please substitute this for a different block');
                        var blockOnchange = targetBlock.onchange;
                        targetBlock.setOnChange(function(x){
                            if(x.blockId===targetBlock.id && (x.element !=='warningOpen')){
                                targetBlock.setWarningText(false);
                                targetBlock.setOnChange(blockOnchange);
                            }
                        });
                    }
                    if (["make_json_with", "lists_create_with"].includes(targetBlockType)) {
                        this.bodyContentType = "application/json";
                    }

                } else {
                    this.setRestErrorMessage(false);
                }
            }
            return rawTypeData;
        },

        // gets a request object for an ajax, without success or failure callbacks (for test popup)
        getRequestSpec: function(context) {
            var queryStrings = JSON.parse(Blockly.JavaScript.valueToCode(this,'QUERY_STRINGS',Blockly.JavaScript.ORDER_NONE));
            var requestData = JSON.parse(Blockly.JavaScript.valueToCode(this,'DATA',Blockly.JavaScript.ORDER_NONE));
            var headers = JSON.parse(Blockly.JavaScript.valueToCode(this,'HEADERS',Blockly.JavaScript.ORDER_NONE));
            var url = encodeURI((eval(Blockly.JavaScript.valueToCode(this,'URL', Blockly.JavaScript.ORDER_NONE))).trim());
            var text_username = this.username_encrypted
                                ? Util.parseString(Blockly.JavaScript.valueToCode(this,'USERNAME',Blockly.JavaScript.ORDER_NONE))
                                : Blockly.JavaScript.valueToCode(this,'USERNAME',Blockly.JavaScript.ORDER_NONE);
            var text_password = this.password_encrypted
                                ? Util.parseString(Blockly.JavaScript.valueToCode(this,'PASSWORD',Blockly.JavaScript.ORDER_NONE))
                                : Blockly.JavaScript.valueToCode(this,'PASSWORD',Blockly.JavaScript.ORDER_NONE);
            var rawTypeRequestData = this.getRawTypeData();
            var accessToken = "Bearer " + context.accessToken;
            var proxyUrl = serverURL + '/proxy/request';
            var method = this.getFieldValue('METHOD');
            var inputType = JSON.stringify({usernameValidated : this.username_encrypted,passwordValidated : this.password_encrypted});
            var data = "";

            var parsedHeaders = {};
            var parsedQueryStrings = {};
            var parsedRequestData = {};
            for (let i = 0; i < Object.keys(headers.key).length; i++) {
                let k = headers.key[i];
                parsedHeaders[k] =  headers.value[i];
            }

            switch (method) {
                case "POST":
                case "PUT":
                case "DELETE":
                    if (this.getFieldValue("DATA_FORMAT") !== "RAW") {
                        for (let i = 0; i < Object.keys(requestData.key).length; i++) {
                            let k = requestData.key[i];
                            parsedRequestData[k] = requestData.value[i];
                        }
                        data = '' +
                            '"request":' + '{\n' +
                            '"body":' +
                            JSON.stringify(parsedRequestData) +
                            '\n},\n';
                    } else {
                        data = '' +
                            '"request":' + '{\n' +
                            '"body":' +
                            rawTypeRequestData +
                            '\n},\n';
                    }
                    break;
                default:
                    if (this.getFieldValue("DATA_FORMAT") !== "RAW") {
                        for (let i = 0; i < Object.keys(queryStrings.key).length; i++) {
                            let k = queryStrings.key[i];
                            parsedQueryStrings[k] = queryStrings.value[i];
                        }
                        data = '' +
                            '"request":' + '{\n' +
                            '"body":' + '{\n' +
                            '\n},\n' +
                            '"params":' + '\n' +
                            JSON.stringify(parsedQueryStrings) +
                            '\n' +
                            '\n},\n';
                    } else {
                        data = '' +
                            '"request":' + '{\n' +
                            '"body":' + '{\n' +
                            '\n},\n' +
                            '"params":' + '\n' +
                            rawTypeRequestData +
                            '\n' +
                            '\n},\n';
                    }
            }

            if (this.isValidJSON(rawTypeRequestData)) {
                this.bodyContentType = "application/json";
            }

            var requestBody = '' +
                '{\n' +
                '"requestUrl":"' + url + '",\n' +
                '"method":"'+ this.getFieldValue('METHOD') + '",\n' +
                '"format":"'+ this.getFieldValue('DATA_FORMAT') + '",\n' +
                '"headers":' + JSON.stringify(parsedHeaders) + ',\n' +
                data +
                '"inputType":'+ inputType + ',\n' +
                '"username":'+ text_username + ',\n' +
                '"password":'+ text_password + ',\n' +
                '"contentType":' + JSON.stringify(this.bodyContentType) + '\n' +
                '\n}\n';

            var r = {
                async: true,
                crossDomain: true,
                url: proxyUrl,
                headers: {Authorization: accessToken},
                method: 'POST',
                dataType:'json',
                contentType: 'application/json',
                data: requestBody
            };

            return {spec:r};
        },

        // get core rest javascript code, appending succes/error callback javascript codes
        getJavascriptCore: function(context, successCallbackCode, errorCallbackCode, useBlocklyModule) {
            var queryStrings = Blockly.JavaScript.valueToCode(this, 'QUERY_STRINGS', Blockly.JavaScript.ORDER_NONE);
            var requestData = Blockly.JavaScript.valueToCode(this, 'DATA', Blockly.JavaScript.ORDER_NONE);
            var headers = Blockly.JavaScript.valueToCode(this, 'HEADERS', Blockly.JavaScript.ORDER_NONE);
            var url = Blockly.JavaScript.valueToCode(this, 'URL', Blockly.JavaScript.ORDER_NONE);
            var username_encrypted = this.username_encrypted;
            var password_encrypted = this.password_encrypted;
            var proxyToggle = this.proxyToggle;
            var rawTypeRequestData = this.getRawTypeData();
            var dataType = this.getFieldValue("DATA_FORMAT");

            // validate username and password, encrypt credentail for api request
            var text_username = "";
            if (Blockly.JavaScript.valueToCode(this, 'USERNAME', Blockly.JavaScript.ORDER_NONE) !== JSON.stringify("")) {
                if (!this.username_encrypted) {
                    var usernameValidated = this.validateBlock(this.getInput('USERNAME').connection.targetBlock());
                    text_username = usernameValidated
                        ? JSON.stringify(encrypting(eval(Blockly.JavaScript.valueToCode(this, 'USERNAME', Blockly.JavaScript.ORDER_NONE))))
                        : Blockly.JavaScript.valueToCode(this, 'USERNAME', Blockly.JavaScript.ORDER_NONE);
                    this.username_encrypted = usernameValidated;
                    username_encrypted = usernameValidated;
                } else {
                    text_username = Util.parseString(Blockly.JavaScript.valueToCode(this, 'USERNAME', Blockly.JavaScript.ORDER_NONE))
                }
            } else {
                text_username = Blockly.JavaScript.valueToCode(this, 'USERNAME', Blockly.JavaScript.ORDER_NONE);
            }
            var passwordValidated = "";
            if (Blockly.JavaScript.valueToCode(this, 'PASSWORD', Blockly.JavaScript.ORDER_NONE) !== JSON.stringify("")) {
                if (!this.password_encrypted) {
                    passwordValidated = this.validateBlock(this.getInput('PASSWORD').connection.targetBlock());
                    var text_password = passwordValidated
                        ? JSON.stringify(encrypting(eval(Blockly.JavaScript.valueToCode(this, 'PASSWORD', Blockly.JavaScript.ORDER_NONE))))
                        : Blockly.JavaScript.valueToCode(this, 'PASSWORD', Blockly.JavaScript.ORDER_NONE);
                        this.password_encrypted = passwordValidated;
                    password_encrypted = passwordValidated;
                } else {
                    text_password = Util.parseString(Blockly.JavaScript.valueToCode(this, 'PASSWORD', Blockly.JavaScript.ORDER_NONE));
                }
            } else {
                text_password = Blockly.JavaScript.valueToCode(this, 'PASSWORD', Blockly.JavaScript.ORDER_NONE);
            }
            var inputType = JSON.stringify({ usernameValidated: username_encrypted, passwordValidated: password_encrypted });
    
            // encrypt credential for blockly xml
            if (username_encrypted) {
                this.getInput('USERNAME').connection.targetBlock().inputList[0].fieldRow[0].value_ = text_username;
                this.getInput('USERNAME').connection.targetBlock().getSvgRoot().style.display = 'none';
                this.initSvg();
                this.render();
                this.workspace.render();
            }
            if (password_encrypted) {
                this.getInput('PASSWORD').connection.targetBlock().inputList[0].fieldRow[0].value_ = text_password;
                this.getInput('PASSWORD').connection.targetBlock().getSvgRoot().style.display = 'none';
                this.initSvg();
                this.render();
                this.workspace.render();
            }
            //Remove Cancel Update button upon save script if exist
            if (this.getInput("CANCEL")) this.removeInput("CANCEL");


            var props = "";
            let propsStr = "";
            var apiArgsStr = '{}';
            var method = this.getFieldValue('METHOD');
            var dataString = '';
            if ( method != "GET" ) {
                dataString = '    data: directCallRequestData,\n';
            }
            var authString = '';
            if (text_username != '""' && text_password!= '""') {
                authString = '    username: '+ text_username + ',' +
                             '    password: '+ text_password + ',';
            }
            if (!proxyToggle) {
                apiArgsStr = '' +
                    '{\n' +
                    '    url: encodeURI(' + url + '.trim()),\n' +
                    '    type: \''+ this.getFieldValue('METHOD') + '\',\n' +
                    '    contentType: contentType,\n' +
                    dataString +
                    authString +
                    '    headers: parsedHeaders\n' +
                    '}';
                let nativeSuccessAsStr = getNativeOnSuccessStr(this.id, successCallbackCode);
                let nativeErrorAsStr = getNativeOnErrorStr(this.id, errorCallbackCode);
                //TODO handle error response in MADP-61727
                propsStr = "NetworkApiModule.invokeApi(" + nativeSuccessAsStr + ", " + nativeErrorAsStr + ", " + apiArgsStr + ");"
            } 
            else {
                var proxyUrl = serverURL + '/proxy/request';
                var accessToken = "Bearer " + context.accessToken;
                props = `
                    {
                        async: true,
                        crossDomain: true,
                        dataType: 'json',
                        contentType: 'application/json',
                        url: '${proxyUrl}',
                        method: 'POST',
                        headers: { 'Authorization': '${accessToken}'},
                        data: JSON.stringify(requestBody)
                    }`;
                let successAsStr = getResponseSuccessStr(this.id, successCallbackCode);
                let errorAsStr = getResponseErrorStr(this.id, errorCallbackCode);
                propsStr = props;
                propsStr = propsStr.slice(0, -1) + ', success: ' + successAsStr + '}';
                propsStr = propsStr.slice(0, -1) + ', error: ' + errorAsStr + '}';
                propsStr = "$.ajax( "+ propsStr +" );";
            }

            return `
                var parsedHeaders = {};
                var parsedQueryStrings = {};
                var parsedData = {};
                var directCallRequestData = {};
                var contentType = '${this.bodyContentType}';
                var rawTypeRequestData = ${rawTypeRequestData};
                
                 if (isJSON(rawTypeRequestData) || Array.isArray(rawTypeRequestData)) { contentType = 'application/json';}
                
                for (let i =0; i < Object.keys(${headers}.key).length; i++) {
                    let k = ${headers}.key[i];
                    parsedHeaders[k] =  ${headers}.value[i];
                }
                
                var method = '${this.getFieldValue('METHOD')}';
                switch (method) {
                    case "POST":
                    case "PUT":
                    case "DELETE":
                        if ("${dataType}" !== "RAW") {
                            for (let i =0; i < Object.keys(${requestData}.key).length; i++) {
                                let k = ${requestData}.key[i];
                                parsedData[k] =  ${requestData}.value[i];
                            }
                            var requestData = {
                                body: parsedData
                            }
                            directCallRequestData = JSON.stringify(parsedData);
                        } else {
                            var requestData = {
                                body: ${rawTypeRequestData}
                            }
                            directCallRequestData = ${rawTypeRequestData};
                        }
                        break;
                    default:
                        if ("${dataType}" !== "RAW") {
                            for (let i =0; i < Object.keys(${queryStrings}.key).length; i++) {
                                let k = ${queryStrings}.key[i];
                                parsedQueryStrings[k] =  ${queryStrings}.value[i];
                            }
                            var requestData = {
                                params: parsedQueryStrings
                            };
                            directCallRequestData = parsedQueryStrings;
                        } else {
                            var requestData = {
                                params: ${rawTypeRequestData}
                            }
                            directCallRequestData = ${rawTypeRequestData};                            
                        }
                }
    
                var requestBody = {
                    requestUrl : encodeURI(${url}.trim()),
                    method: '${this.getFieldValue('METHOD')}',
                    format: '${this.getFieldValue('DATA_FORMAT')}',
                    headers: parsedHeaders,
                    request: requestData,
                    inputType: ${inputType},
                    username: ${text_username},
                    password: ${text_password},
                    contentType: contentType
                    
                };
    
                // indicate start of another ajax request
                ${ useBlocklyModule
                    ? `if (BlocklyModule && BlocklyModule.onRestAjaxStarted)
                        BlocklyModule.onRestAjaxStarted();`
                    : '' }
                try {    
                    ${propsStr}
                } catch (e) {
                    console.log(e);
                }
                `;
        }
    }
}

export default RestCore;
