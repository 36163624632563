import React, { useState, useRef, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import ReactQuill from 'react-quill';
import { IoMdClose } from "react-icons/io";
import { Rnd } from 'react-rnd';
import 'react-quill/dist/quill.snow.css';
import './PopupModal.scss';

const ResizableModal = ({ open, setOpen, content, setContent, modalChecked, setModalPosition, modalPosition, setModalChecked }) => {
  const [isDraggable, setIsDraggable] = useState(true);
  const quillRef = useRef(null);
  const modalRef = useRef(null);

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link']
    ],
    clipboard: {
      matchVisual: false
    }
  };

  // Add a custom class to the toolbar to only display the link icon when some text has been selected
  useEffect(() => {
    if (open) {
      const quill = quillRef.current.getEditor();
      const toolbar = quill.container.parentElement.querySelector('.ql-toolbar');
      
      if (toolbar) {
        toolbar.classList.add('custom-toolbar');
      }
      
      const updateToolbarVisibility = () => {
        const selection = quill.getSelection();
        if (toolbar) {
          if (selection && selection.length > 0) {
            toolbar.classList.add('has-selection');
          } else {
            toolbar.classList.remove('has-selection');
          }
        }
      };

      updateToolbarVisibility();

      quill.on('selection-change', updateToolbarVisibility);

      return () => {
        quill.off('selection-change', updateToolbarVisibility);
      };
    }
  }, [open]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const quillElement = quillRef.current.getEditor().container;
      const toolbarElement = quillElement.parentElement.querySelector('.ql-toolbar');
      const isQuillClick = quillElement.contains(event.target) || toolbarElement.contains(event.target);

      if (!isQuillClick) {
        setIsDraggable(true);
        if (quillRef.current) {
          quillRef.current.getEditor().blur();
        }
      }
    };

    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  return (
    <div>
      {open && (
        <Rnd
          className='popup-modal'
          size={{ width: modalPosition.width, height: modalPosition.height }}
          position={{ x: modalPosition.x, y: modalPosition.y }}
          onDragStop={(e, d) => {
            setModalPosition({ ...modalPosition, x: d.x, y: d.y });
          }}
          onResizeStop={(e, direction, ref, delta, position) => {
            setModalPosition({ ...modalPosition, height: ref.offsetHeight, width: ref.offsetWidth });
          }}
          disableDragging={!isDraggable}
          minWidth={310}
          minHeight={250}
          bounds="window"
        >
          <div className='container-div'>
            <h3 className='popup-header'>Blockly Script Notes</h3>
            <div className='header-div'>
              <IoMdClose
                size={24}
                className='close-icon'
                onClick={() => setOpen()}
              />
            </div>
          </div>
          <div ref={modalRef} className='quill-container-div'>
            <div className='quill-container'>
              <ReactQuill 
                ref={quillRef}
                value={content}
                onChange={setContent}
                modules={modules}
                bounds={'.quill-container'}
                placeholder='Use this modal to add comments describing your Blockly script'
                className='react-quill'
                onFocus={() => setIsDraggable(false)}
              />
              <div className='label-div'>
                <label className='popup-label'>
                  <input
                    type="checkbox"
                    checked={modalChecked}
                    onChange={(e) => setModalChecked(e.target.checked)}
                  />
                  Show on Launch
                </label>
              </div>
            </div>
          </div>
        </Rnd>
      )}
    </div>
  );
};

export default withRouter(ResizableModal);
