import React from 'react';
import _ from "underscore";

// Connections, Connections, connections, connections
const Connections = (props) => {
    return (
        <category name='Connections' id='connections' iconclass='category with-icon-paths snap-icon-connection_dual'>
            <label text="Connections"/>
            { _.values(props.blocks).map((Block, id) => <Block.component context={props.context} setRestBlockError = {props.setRestBlockError} key={id}/>) }
        </category>
    )
};

export default Connections
