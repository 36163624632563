import React from 'react';
import _ from "underscore";

// Loops, loops, loops

const Loops  = function(props){
        return (
            <category name='Loops' id='loops' iconclass='category with-icon-paths snap-icon-loop_dual'>
                <label text="Loops"></label>
                { _.values(props.blocks).map((Block, id) => <Block.component context={props.context} key={id}/>) }
            </category>
        )
}

export default Loops;