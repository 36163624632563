/* global Blockly */
import React from 'react';

function defineBlock() {
    return {
        init: function () {
            this.appendValueInput('confirmInput')
                .setCheck("Boolean")
                .appendField("if");
            this.appendStatementInput('ok')
            this.appendDummyInput("cancelBlock")
                .appendField("else");   
            this.appendStatementInput('cancel');

            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setHelpUrl(Blockly.BASE_HELP_URL + "#text");
            this.setColour(Blockly.Msg.TEXTS_HUE);
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {

            let value = Blockly.JavaScript.valueToCode(block, 'confirmInput', Blockly.JavaScript.ORDER_NONE);
            let ifBlock = Blockly.JavaScript.statementToCode(block, 'ok');
            let elseBlock = Blockly.JavaScript.statementToCode(block, 'cancel');
            return `let headerInput = ${value}.header;
            let messageInput = ${value}.message;
            UtilityModule.genericDialog(Constants.dialog.confirmDialog, headerInput, messageInput, {
                buttonGeneric: "ok",
                buttonSave: "cancel"
            }, function (res) {
                    if (res === true) {
                        ${ifBlock} 
                       
                    }
                    else {
                        ${elseBlock} 
                    }

                }
            );
            `;
            
        }
    }
}

const Confirm = () => {
    const name = 'confirm';
    Blockly.Blocks[name] = defineBlock();
    let generators = defineGenerators();
    for (let i in generators) {
        Blockly[i][name] = generators[i];
    }

    return (
        <block type={name}>
            <value name="confirmInput">
                <block type="confirm_dialogue"></block> 
                    <shadow type="text"></shadow>
            </value>
        </block>
    )
};

export default Confirm