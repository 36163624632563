import React from 'react';
import _ from "underscore";


export default function Sound (props){

    return (
        <category name='Sound' id='sound' iconclass='category with-icon-paths snap-icon-sound_dual'>
            <label text={"Sound"}/>
            {_.values(props.blocks).map((Component, id) => <Component.component { ...props } key={id}/>)}       
        </category>
    );
}